import Loader from "common/loader";
import { ProtectedRoute } from "components";
import Overview from "components/Remit/overview";
import DefaultLayout from "layouts/DefaultLayout";
import RoleandgroupsPage from "pages/Accessandroles";
import ApprovalsPage from "pages/Approvals";
import BranchDetails from "pages/BranchDetails/index";
import BranchRegistrationPage from "pages/BranchRegistration";
import ConfirmCollection from "pages/ConfirmCollection";
import ContactPage from "pages/Contacts";
import CreateContact from "pages/Create Opportunities";
import CollectDocs from "pages/Create Opportunities/collectdocs";
import CreateCollection from "pages/CreateCollection";
import CorporateLead from "pages/CreateCorporateLead";
import DealSuccess from "pages/CreateDeal/dealsuccess";
import CreateNewDeal from "pages/CreateDeal/newdeal";
import CreateOldDeal from "pages/CreateDeal/oldcreatedeal";
import CreateForexQuotation from "pages/CreateForexQuotation";
import CreateOrder from "pages/CreateOrder";
import CreatePartnerLead from "pages/CreatePartnerLead";
import CreatePayout from "pages/CreatePayout";
import CreateRemit from "pages/CreateRemit/Index";
import Ordersuccess from "pages/CreateRemit/ordersuccess";
import CreateRemittanceQuotation from "pages/CreateRemittanceQuotation/step1";
import CreateStaffPage from "pages/CreateStaff";
import LoginPage1 from "pages/CustomerApp/login";
import CustomerDetailsPage from "pages/CustomerDetails";
import CustomerRegistration from "pages/CustomerRegistration";
import NewCustomersPage from "pages/Customers/newcustomer";
import DashboardPage from "pages/Dashboard";
import DealDetailsPage from "pages/DealDetails";
import NewDealDetailsPage from "pages/DealDetails/newdealdetails";
import DealsPage from "pages/Deals";
import TaskPage from "pages/Deals/newdealpage";
import EntryForm from "pages/EntryForm";
import NewFinancePage from "pages/Finance/newfinance";
import ForgetPasswordPage from "pages/ForgetPassword";
import Groups from "pages/Groups";
import CreateGroup from "pages/Groups/create group";
import GroupList from "pages/Groups/overview";
import Home from "pages/Home";
import HomeLayout from "pages/HomeLayout";
import LeadContacts from "pages/LeadContacts";
import CorporateContact from "pages/LeadContacts/corporate_contact";
import PartnerContact from "pages/LeadContacts/partner_contact";
import Leads from "pages/Leads";
import LeadGeneric from "pages/Leads/lead";
import LoginPage from "pages/Login";
import LogoutPage from "pages/Logout";
import NotFound from "pages/NotFound";
import Opportunities from "pages/Opportunities";
import Corporateleaddetail from "pages/Opportunities/corporateleaddetail";
import Opp from "pages/Opportunities/opp";
import OpportunitiesOverview from "pages/Opportunities/overview";
import Partnerleaddetail from "pages/Opportunities/partnerleaddetail";
import OpportunitiesSheet from "pages/Opportunities/Sheet";
import NewOrderDetailsPage from "pages/OrderDetails/neworderdetails";
import OrdersPage from "pages/Orders/neworders";
import PartnerDetailsPage from "pages/PartnerDetails";
import PartnerRegistration from "pages/PartnerRegistration";
import NewPartnersPage from "pages/Partners/newpartners";
import Profile from "pages/Profile";
import NotificationPreferences from "pages/Profile/notficationpreference";
import Quotation from "pages/Quotations";
import QuotationOverview from "pages/Quotations/overview";
import RedirectPortal from "pages/Redirect Portal";
import RedirectExisting from "pages/Redirect Portal/existing";
import OrderSuccess from "pages/Redirect Portal/ordersuccess";
import Payment from "pages/Redirect Portal/payment";
import Track from "pages/Redirect Portal/track";
import Remit from "pages/Remit";
import SetPasswordPage from "pages/SetPassword";
import StaffPage from "pages/Staff";
import StaffdetailsPage from "pages/StaffDetails";
import { lazy, Suspense } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import CorporateRegistration from "pages/CorporateRegistration";
import OpportunityDetails from "pages/Opportunities/opportunitydetails";
const Reports = lazy(() => import("pages/Reports"));
const Settings = lazy(() => import("pages/Settings"));
const Company = lazy(() => import("pages/Company"));
const Createregion = lazy(() => import("pages/CreateRegion"));
const TestPage = lazy(() => import("pages/Test")); //testing page
const mydefaultmargin = lazy(() => import("pages/mydefaultmargin"));
const mymargins = lazy(() => import("pages/MyMargin"));
const Region = lazy(() => import("pages/Region"));
const GicOrders = lazy(() => import("pages/Gic-Orders"));
const ConfirmPayout = lazy(() => import("pages/ConfirmPayout"));
const RetailInteraction = lazy(() => import("pages/CreateRetailInteraction"));
const CorporateInteraction = lazy(
  () => import("pages/CreateCompanyInteraction"),
);

const ProjectRoutes = () => {
  // const authtoken = localStorage.getItem("authToken");

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          {/* AUTH */}

          <Route path="/" element={<LoginPage />} />
          <Route path="/test" element={<TestPage />} />
          <Route
            path="/logout"
            element={<ProtectedRoute element={LogoutPage} />}
          />
          <Route path="/forgetpassword" element={<ForgetPasswordPage />} />
          <Route
            path="/setpassword"
            element={<ProtectedRoute element={SetPasswordPage} />}
          />
          <Route
            path="/createRemit"
            element={<ProtectedRoute element={CreateRemit} />}
          />
          <Route
            path="/createnewdeal"
            element={<ProtectedRoute element={CreateNewDeal} />}
          />

          {/* DefaultLayout */}
          <Route path="/" element={<DefaultLayout />}>
            <Route
              path="/profile"
              element={<ProtectedRoute element={Profile} />}
            >
              <Route
                index
                element={<ProtectedRoute element={NotificationPreferences} />}
              />
            </Route>
            <Route
              path="/company"
              element={<ProtectedRoute element={Company} />}
            />
            <Route
              path="/createregion"
              element={<ProtectedRoute element={Createregion} />}
            />
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={DashboardPage} />}
            />
            <Route path="/remit" element={<ProtectedRoute element={Remit} />}>
              <Route
                path="orders"
                element={<ProtectedRoute element={OrdersPage} />}
              />
              <Route
                path="olddeals"
                element={<ProtectedRoute element={DealsPage} />}
              />
              <Route
                path="leads"
                element={
                  <ProtectedRoute element={<LeadGeneric type="Remittance" />} />
                }
              />
              <Route
                path="deals"
                element={<ProtectedRoute element={TaskPage} />}
              />
              <Route
                path="dealdetails/:id"
                element={<ProtectedRoute element={NewDealDetailsPage} />}
              />
              <Route
                path="dealsuccess"
                element={<ProtectedRoute element={DealSuccess} />}
              />
              <Route
                path="ordersuccess"
                element={<ProtectedRoute element={Ordersuccess} />}
              />
              <Route index element={<ProtectedRoute element={Overview} />} />
              <Route
                path="orderdetails/:id"
                element={<ProtectedRoute element={NewOrderDetailsPage} />}
              />
            </Route>

            <Route
              path="/quotations"
              element={<ProtectedRoute element={Quotation} />}
            >
              <Route
                index
                element={
                  <ProtectedRoute
                    element={<QuotationOverview activeTab="forex" />}
                  />
                }
              />
            </Route>
            <Route
              path="/entry-form"
              element={<ProtectedRoute element={EntryForm} />}
            />
            <Route
              path="/dealdetails2:id"
              element={<ProtectedRoute element={DealDetailsPage} />}
            />

            <Route path="/" element={<ProtectedRoute element={HomeLayout} />}>
              <Route
                path="/staff"
                element={<ProtectedRoute element={StaffPage} />}
              />
              <Route
                path="/customers"
                element={<ProtectedRoute element={NewCustomersPage} />}
              />
              <Route
                path="/lead/contacts"
                element={<ProtectedRoute element={LeadContacts} />}
              />
              <Route
                path="/lead/contacts/partner"
                element={<ProtectedRoute element={PartnerContact} />}
              />
              <Route
                path="/lead/contacts/corporate"
                element={<ProtectedRoute element={CorporateContact} />}
              />
              <Route
                path="/finance"
                element={<ProtectedRoute element={NewFinancePage} />}
              />
              <Route
                path="/accessandroles"
                element={<ProtectedRoute element={RoleandgroupsPage} />}
              />
              <Route
                path="/approvals"
                element={<ProtectedRoute element={ApprovalsPage} />}
              />
              <Route
                path="/partners"
                element={<ProtectedRoute element={NewPartnersPage} />}
              />
            </Route>

            <Route
              path="/reports"
              element={<ProtectedRoute element={Reports} />}
            />
            <Route
              path="/settings"
              element={<ProtectedRoute element={Settings} />}
            />
            <Route
              path="/branchregistration"
              element={<ProtectedRoute element={BranchRegistrationPage} />}
            />
            <Route
              path="/branchregistration:id"
              element={<ProtectedRoute element={BranchRegistrationPage} />}
            />
            <Route
              path="/customerdetails/:id"
              element={<ProtectedRoute element={CustomerDetailsPage} />}
            />
            <Route path="/leads" element={<ProtectedRoute element={Leads} />}>
              <Route
                index
                element={<ProtectedRoute element={OpportunitiesOverview} />}
              />
              <Route
                path="contact/:id"
                element={<ProtectedRoute element={OpportunityDetails} />}
              />
              <Route
                path="partner/:id"
                element={<ProtectedRoute element={Partnerleaddetail} />}
              />
              <Route
                path="corporate/:id"
                element={<ProtectedRoute element={Corporateleaddetail} />}
              />
              <Route
                path="visa"
                element={
                  <ProtectedRoute element={<LeadGeneric type="Visa" />} />
                }
              />
              <Route
                path="forex"
                element={
                  <ProtectedRoute element={<LeadGeneric type="Forex" />} />
                }
              />
              <Route
                path="travel"
                element={
                  <ProtectedRoute element={<LeadGeneric type="Travel" />} />
                }
              />
            </Route>

            <Route path="/group" element={<ProtectedRoute element={Groups} />}>
              <Route index element={<ProtectedRoute element={GroupList} />} />
              {/* <Route
              path="createlead"
              element={<ProtectedRoute element={CreateContact} />}
            />
            <Route
              path="createcontact"
              element={<ProtectedRoute element={<OpportunitiesSheet />} />}
            /> */}
            </Route>

            <Route
              path="/staffdetails:id"
              element={<ProtectedRoute element={StaffdetailsPage} />}
            />
            <Route
              path="/mydefaultmargin"
              element={<ProtectedRoute element={mydefaultmargin} />}
            />
            <Route
              path="/partnerdetails/:id"
              element={<ProtectedRoute element={PartnerDetailsPage} />}
            />
            <Route
              path="/branchdetails:id"
              element={<ProtectedRoute element={BranchDetails} />}
            />
            <Route
              path="/regions"
              element={<ProtectedRoute element={Region} />}
            />
            <Route
              path="/gicorders"
              element={<ProtectedRoute element={GicOrders} />}
            />
            <Route
              path="/mymargins"
              element={<ProtectedRoute element={mymargins} />}
            />
            <Route
              path="/createcollection"
              element={<ProtectedRoute element={CreateCollection} />}
            />
            <Route
              path="/confirmcollection:id"
              element={<ProtectedRoute element={ConfirmCollection} />}
            />
            <Route
              path="/createpayout"
              element={<ProtectedRoute element={CreatePayout} />}
            />
            <Route
              path="/confirmpayout:id"
              element={<ProtectedRoute element={ConfirmPayout} />}
            />

            <Route
              path="/contacts"
              element={<ProtectedRoute element={ContactPage} />}
            />
            <Route
              path="/retailinteraction"
              element={<ProtectedRoute element={RetailInteraction} />}
            />
            <Route
              path="/corporateinteraction"
              element={<ProtectedRoute element={CorporateInteraction} />}
            />
          </Route>

          <Route
            path="/createforexquotation"
            element={
              <ProtectedRoute
                element={<ProtectedRoute element={CreateForexQuotation} />}
              />
            }
          />

          <Route
            path="/createremittancequotation"
            element={<ProtectedRoute element={CreateRemittanceQuotation} />}
          />
          <Route
            path="/lead"
            element={<ProtectedRoute element={Opportunities} />}
          >
            <Route index element={<Navigate to="/" replace />} />
            <Route
              path="createlead"
              element={<ProtectedRoute element={CreateContact} />}
            />
            <Route
              path="partner/createlead"
              element={<ProtectedRoute element={CreatePartnerLead} />}
            />
            <Route
              path="corporate/createlead"
              element={<ProtectedRoute element={CorporateLead} />}
            />
            <Route
              path="createcontact"
              element={
                <ProtectedRoute element={<OpportunitiesSheet type="multi" />} />
              }
            />
            <Route
              path="createcontact/partner"
              element={
                <ProtectedRoute
                  element={<OpportunitiesSheet type="partner" />}
                />
              }
            />
            <Route
              path="createcontact/corporate"
              element={
                <ProtectedRoute
                  element={<OpportunitiesSheet type="corporate" />}
                />
              }
            />
          </Route>

          <Route path="/redirect" element={<RedirectPortal />}>
            <Route index element={<RedirectExisting />} />
            <Route path="ordersuccess" element={<OrderSuccess />} />
            <Route path="payment" element={<Payment />} />
            <Route path="track" element={<Track />} />
            <Route path="404" element={<NotFound />} />
          </Route>
          <Route
            path="/createstaff"
            element={<ProtectedRoute element={CreateStaffPage} />}
          />
          <Route
            path="/partnerregistration"
            element={<ProtectedRoute element={PartnerRegistration} />}
          />
          <Route
            path="/corporateregistration"
            element={<ProtectedRoute element={CorporateRegistration} />}
          />
          <Route
            path="/customerregistration"
            element={<ProtectedRoute element={CustomerRegistration} />}
          />
          <Route
            path="/create-group"
            element={<ProtectedRoute element={CreateGroup} />}
          />
          <Route
            path="/createorder"
            element={<ProtectedRoute element={CreateOrder} />}
          />
          <Route path="/collectdocs" element={<CollectDocs />} />
          <Route path="/collect-docs" element={<LoginPage1 />} />
          <Route
            path="/createolddeal"
            element={<ProtectedRoute element={CreateOldDeal} />}
          />

          {/* DELETE LATER */}

          <Route path="/lv-dashboard" element={<Home />} />

          {/* <Route path="/generate-quotation" element={<GenerateQuotation />} /> */}

          {/* 404 Not Found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
};
export default ProjectRoutes;
