import { AxiosPromise, AxiosRequestConfig } from "axios";
import { apis } from "../../index";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const get_opportunities = `${BASE_URL}:rBHQT4XV/get_opportunity`;
const search_contacts = `${BASE_URL}:rBHQT4XV/contacts`;
const create_opp_new_contact = `${BASE_URL}:rBHQT4XV/coorperate_opportunities_newCompany`;
const get_product_list = `${BASE_URL}:rBHQT4XV/product`;
const post_new_contact = `${BASE_URL}:rBHQT4XV/opportunities_new_v2`;
const get_staff_list = `${BASE_URL}:rBHQT4XV/staff`;
const get_comapany = `${BASE_URL}:rBHQT4XV/query_contacts_company`;
const get_opportunity_list_by_comapany = `${BASE_URL}:rBHQT4XV/opportunity_by_id`;
const post_existing_opportunity = `${BASE_URL}:rBHQT4XV/opportunities_existing_v2`;
const partnerAcquisition = `${BASE_URL}:rBHQT4XV/partnerAcquisition`;
const patch_update_comapny_details = `${BASE_URL}:rBHQT4XV/coorporate_company/:id`;
const get_opportunities_by_opp_id_with_interactions = `${BASE_URL}:rBHQT4XV/opportunity/:id`;
const patch_update_ooportunity_status = `${BASE_URL}:rBHQT4XV/opportunity/:id`;
const add_coorporate_interactions = `${BASE_URL}:rBHQT4XV/corporate_interactions`;
const get_comapany_by_id = `${BASE_URL}:rBHQT4XV/coorporate_company/:id`;
const get_contacts_referral = `${BASE_URL}:rBHQT4XV/contacts_for_refferal`;
const get_contacts_detail_by_id = `${BASE_URL}:rBHQT4XV/contacts/:id`;
const patch_updata_interaction_status = `${BASE_URL}:rBHQT4XV/corporate_interactions/:id`;
const get_branche_by_id =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:yyd3i3Ww/branch_by_Id";
const get_doc_list = `${BASE_URL}:rBHQT4XV/getDocList_0`;
const verify_user_and_document = `${BASE_URL}:rBHQT4XV/submitDocgateway`;
const submit_doc = `${BASE_URL}:rBHQT4XV/submitDoc`;
const download_doc = `${BASE_URL}:rBHQT4XV/getUploadedDoc`;
const request_doc = `${BASE_URL}:rBHQT4XV/requestDoc`;
const doc_download_tracker = `${BASE_URL}:rBHQT4XV/downloadTracker`;
const search_contacts_new = `${BASE_URL}:rBHQT4XV/searchContact`;
const create_order =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/lead_to_order";
const create_contact =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:vQ4fLKDn/createContacts";

const get_leads =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:vQ4fLKDn/listOfCustomerLead_0";

const generate_summary = `${BASE_URL}:rBHQT4XV/Ai_summary`;

const post_req_doc = `${BASE_URL}:rBHQT4XV//req_forex_doc`;

const filtered_leads = `https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/Leads_with_filters`;

const clearReminder =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/clearReminder";

const get_contactslist =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:vQ4fLKDn/listofcontacts";

const get_partnerContactList =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:vQ4fLKDn/listOfPartnerContacts";

const post_add_interactions = `${BASE_URL}:rBHQT4XV/corporate_interactions_0`;
const corp_add_interaction = `${BASE_URL}:AtzopIo1/corporate_interactions_1`;
const patch_link_partner = `${BASE_URL}:7K7mNQ3j/linkPartner`;
const patch_link_corporate = `${BASE_URL}:AtzopIo1/linkCorporate`;
const post_corporate_lead = `${BASE_URL}:AtzopIo1/corporateLead`;

export type GetOpportunityReqType = Partial<{
  headers: {
    Authorization: string;
  };
  page: number;
  searchQuery: string;
  per_page: number;
  offset: number;
  pipelineType: string;
}>;
export type GetOpportunityResponseType = Partial<{}>;

export const getOpportunities = (
  payload: AxiosRequestConfig<GetOpportunityReqType>,
): AxiosPromise<GetOpportunityResponseType> => {
  return apis.get(get_opportunities, payload);
};

export type GetContactReqType = Partial<{
  headers: {
    Authorization: string;
  };
  comapnyId: string | number;
  number: string;
}>;
export type GetContactResponseType = Partial<{
  id: number;
  created_at: string;
  contactName: string;
  contactNumber: string;
  contactPersonType: string;
  contactEmail: string;
  contactAddress: string;
}>[];
export const getContacts = (
  payload: AxiosRequestConfig<GetContactReqType>,
): AxiosPromise<GetContactResponseType> => {
  return apis.get(search_contacts, payload);
};

export type CreateOpportunityReqType = Partial<{
  headers: {
    Authorization: string;
  };
  companyName: string;
  companyEmail: string;
  companyContact: string;
  companyAddress: string;
  companyType: string;
}>;

export type CreateOpportunityResponseType = Partial<{}>;

export const postCreateOpportunitynewContact = (
  payload: AxiosRequestConfig<CreateOpportunityReqType>,
): AxiosPromise<CreateOpportunityResponseType> => {
  return apis.post(create_opp_new_contact, payload);
};

export type GetProductsReqType = Partial<{
  headers: {
    Authorization: string;
  };
}>;

export type GetProductsResponseType = Partial<{
  id: number;
  name: string;
  created_at: string;
  details: {};
}>[];

export const getProducts = (
  payload: AxiosRequestConfig<GetProductsReqType>,
): AxiosPromise<GetProductsResponseType> => {
  return apis.get(get_product_list, payload);
};

export type CreateContactReqType = Partial<{
  headers: {
    Authorization: string;
  };
}>;

export type CreateContactResponseType = Partial<{}>;

export const postCreateContact = (
  payload: AxiosRequestConfig<CreateContactReqType>,
): AxiosPromise<CreateContactResponseType> => {
  return apis.post(post_new_contact, payload);
};

export type GetStaffReqType = Partial<{
  headers: {
    Authorization: string;
  };
}>;
export type GetStaffResponseType = Partial<{}>[];

export const getStaff = (
  payload: AxiosRequestConfig<GetStaffReqType>,
): AxiosPromise<GetStaffResponseType> => {
  return apis.get(get_staff_list, payload);
};

export type GetCompanyReqType = Partial<{
  headers: {
    Authorization: string;
  };
  query: string;
  page: number;
  per_page: number;
  pipelineType: string;
}>;
export type GetCompanyResponseType = Partial<{}>[];

export const getCompany = (
  payload: AxiosRequestConfig<GetCompanyReqType>,
): AxiosPromise<GetCompanyResponseType> => {
  return apis.get(get_comapany, payload);
};

export type GetOpportunityByCompanyReqType = Partial<{
  headers: {
    Authorization: string;
  };
  companyID: number;
  contactID: number;
}>;

export type GetOpportunityByCompanyResponseType = Partial<{}>;

export const getOpportunityByCompany = (
  payload: AxiosRequestConfig<GetOpportunityByCompanyReqType>,
): AxiosPromise<GetOpportunityByCompanyResponseType> => {
  return apis.get(get_opportunity_list_by_comapany, payload);
};

export type PostExistingOpportunityReqType = Partial<{
  headers: {
    Authorization: string;
  };
}>;

export type PostExistingOpportunityResponseType = Partial<{}>;

export const postExistingOpportunity = (
  payload: AxiosRequestConfig<PostExistingOpportunityReqType>,
): AxiosPromise<PostExistingOpportunityResponseType> => {
  return apis.post(post_existing_opportunity, payload);
};

// partner acquisition lead
export type PartnerAcquisitionReqType = Partial<{
  headers: {
    Authorization: string;
  };
  body: {
    entityName: string;
    type: string;
    city: string;
    state: string;
    rm_id: number;
    services: string[];
    channel_of_interaction_id: number;
    meeting_time: string | null;
    meetingStaff_id: number[];
    notes: string;
    contacts_id: number | string;
    relationship_with_partner: string;
  };
}>;

export type PartnerAcquisitionResponseType = Partial<{
  message: string;
}>;

export const partnerAcquisitionOpportunity = (
  payload: AxiosRequestConfig<PartnerAcquisitionReqType>,
): AxiosPromise<PartnerAcquisitionResponseType> => {
  return apis.post(partnerAcquisition, payload);
};

export type PutUpdateCompanyDetailsReqType = Partial<{
  headers: {
    Authorization: string;
  };
  id: string | number;
  company_name: string;
  email: string;
  pipelineType: string;
  address: string;
  phone: string;
  contactName: string;
  contactNumber: string;
  contactEmail: string;
  contactPersonType: string;
  typeOfPartner: string;
  contactAddress: string[];
}>;

export type PutUpdateCompanyDetailsResponseType = Partial<{}>;

export const PutUpdateCompanyDetails = (
  payload: AxiosRequestConfig<PutUpdateCompanyDetailsReqType>,
): AxiosPromise<PutUpdateCompanyDetailsResponseType> => {
  const id = payload?.data?.id;
  const url = patch_update_comapny_details.replace(":id", id as string);
  return apis.patch(url, payload);
};

export type PostCorporateInteractionsReqType = Partial<{
  headers: {
    Authorization: string;
  };
  remarks: string;
  channel_of_interaction_id: string | number;
  contact: number;
  source: string;
  subject: string;
  meeting_time: Date;
  meetingStaff_id: number[];
  note: string;
  opportunity_id: string | number;
  quotation_pdf: string;
}>;

export type PostCorporateInteractionsResponseType = Partial<{}>;

export const postCorporateInteractions = (
  payload: AxiosRequestConfig<PostCorporateInteractionsReqType>,
): AxiosPromise<PostCorporateInteractionsResponseType> => {
  return apis.post(add_coorporate_interactions, payload);
};

export type GetOpportunityByIdReqType = Partial<{
  headers: {
    Authorization: string;
  };
  id: string | number;
}>;

export type GetOpportunityByIdResponseType = Partial<{}>;

export const getOpportunityById = (
  payload: AxiosRequestConfig<GetOpportunityByIdReqType>,
): AxiosPromise<GetOpportunityByIdResponseType> => {
  const id = payload?.data?.id;
  const url = get_opportunities_by_opp_id_with_interactions.replace(
    ":id",
    id as string,
  );
  return apis.get(url, payload);
};

export type PutUpdateOpportunityStatusReqType = Partial<{
  headers: {
    Authorization: string;
  };
  id: string | number;
  isopen: string;
  reasonToClose: string;
  assignedto_id: string | number;
  followup_time: Date | string;
  reminder_note: string;
  notes: string;
}>;

export type PutUpdateOpportunityStatusResponseType = Partial<{}>;

export const putUpdateOpportunityStatus = (
  payload: AxiosRequestConfig<PutUpdateOpportunityStatusReqType>,
): AxiosPromise<PutUpdateOpportunityStatusResponseType> => {
  const id = payload?.data?.id;
  const url = patch_update_ooportunity_status.replace(":id", id as string);
  return apis.patch(url, payload);
};

export type GetCompanyByIdReqType = Partial<{
  headers: {
    Authorization: string;
  };
  id: string | number;
}>;

export type GetCompanyByIdResponseType = Partial<{}>;

export const getCompanyById = (
  payload: AxiosRequestConfig<GetCompanyByIdReqType>,
): AxiosPromise<GetCompanyByIdResponseType> => {
  const id = payload?.data?.id;
  const url = get_comapany_by_id.replace(":id", id as string);
  return apis.get(url, payload);
};

export type GetContactsForReferralReqType = Partial<{
  headers: {
    Authorization: string;
  };
  typeOfContact: "partner" | "staff";
}>;

export type GetContactsForReferralResponseType = Partial<{}>;

export const getContactsForReferral = (
  payload: AxiosRequestConfig<GetContactsForReferralReqType>,
): AxiosPromise<GetContactsForReferralResponseType> => {
  return apis.get(get_contacts_referral, payload);
};

export type GetContactDetailByIdReqType = Partial<{
  headers: {
    Authorization: string;
  };
  id: string | number;
}>;

export type GetContactDetailByIdResponseType = Partial<{}>;

export const getContactDetailById = (
  payload: AxiosRequestConfig<GetContactDetailByIdReqType>,
): AxiosPromise<GetContactDetailByIdResponseType> => {
  const id = payload?.data?.id;
  const url = get_contacts_detail_by_id.replace(":id", id as string);
  return apis.get(url, payload);
};

export type PutUpdateInteractionStatusReqType = Partial<{
  headers: {
    Authorization: string;
  };
  id: string | number;
  isOpen: string;
  quotation_pdf: string;
}>;

export type PutUpdateInteractionStatusResponseType = Partial<{}>;

export const putUpdateInteractionStatus = (
  payload: AxiosRequestConfig<PutUpdateInteractionStatusReqType>,
): AxiosPromise<PutUpdateInteractionStatusResponseType> => {
  const id = payload?.data?.id;
  const url = patch_updata_interaction_status.replace(":id", id as string);
  return apis.patch(url, payload);
};

export type GetBranchByIdReqType = Partial<{
  headers: {
    Authorization: string;
  };
  Chaneel_id: string | number;
}>;

export type GetBranchByIdResponseType = Partial<{}>;

export const getBranchById = (
  payload: AxiosRequestConfig<GetBranchByIdReqType>,
): AxiosPromise<GetBranchByIdResponseType> => {
  return apis.get(get_branche_by_id, payload);
};

export type GetDocumentMappingReqType = Partial<{
  headers: {
    Authorization: string;
  };
  contacts_id: string;
  opportunity_id: string;
}>;

export type GetDocumentMappingResponseType = Partial<{}>;

export const getDocumentMapping = (
  payload: AxiosRequestConfig<GetDocumentMappingReqType>,
): AxiosPromise<GetDocumentMappingResponseType> => {
  return apis.get(get_doc_list, payload);
};

export type VerifyUserAndDocumentReqType = Partial<{
  opportunity_id: string | number;
  uuid: string;
}>;

export type VerifyUserAndDocumentResponseType = Partial<{}>;

export const verifyUserAndDocument = (
  payload: AxiosRequestConfig<VerifyUserAndDocumentReqType>,
): AxiosPromise<VerifyUserAndDocumentResponseType> => {
  return apis.get(verify_user_and_document, payload);
};

export type SubmitDocReqType = Partial<{}>;

export type SubmitDocResponseType = Partial<{}>;

export const submitDoc = (
  payload: AxiosRequestConfig<SubmitDocReqType>,
): AxiosPromise<SubmitDocResponseType> => {
  return apis.post(submit_doc, payload);
};

export type DownloadDocReqType = Partial<{
  headers: {
    Authorization: string;
  };
  opportunity_id: string | number;
}>;

export type DownloadDocResponseType = Partial<[]>;

export const downloadDoc = (
  payload: AxiosRequestConfig<DownloadDocReqType>,
): AxiosPromise<DownloadDocResponseType> => {
  return apis.get(download_doc, payload);
};

export type RequestDocReqType = Partial<{
  headers: {
    Authorization: string;
  };
  opportunity_id: string | number;
  exp_time: string;
  note: string;
  interaction_note: string;
  channel: string;
  doc_list: { filename: string }[];
  channel_of_interaction_id: string;
}>;

export type RequestDocResponseType = Partial<{}>;

export const requestDoc = (
  payload: AxiosRequestConfig<RequestDocReqType>,
): AxiosPromise<RequestDocResponseType> => {
  return apis.post(request_doc, payload);
};

export type DocDownloadTrackerReqType = Partial<{
  headers: {
    Authorization: string;
  };
  opportunity_id: string | number;
  contacts_id: string | number;
}>;

export type DocDownloadTrackerResponseType = Partial<{}>;

export const docDownloadTracker = (
  payload: AxiosRequestConfig<DocDownloadTrackerReqType>,
): AxiosPromise<DocDownloadTrackerResponseType> => {
  return apis.patch(doc_download_tracker, payload);
};

export type SearchContactReqType = Partial<{
  headers: {
    Authorization: string;
  };
  searchQuery: string;
  type: string;
  paging: {
    page: number;
    per_page: number;
  };
}>;

export type SearchContactResponseType = Partial<{}>;

export const searchContact = (
  payload: AxiosRequestConfig<SearchContactReqType>,
): AxiosPromise<SearchContactResponseType> => {
  return apis.get(search_contacts_new, payload);
};

export const searchContact1 = (
  payload: AxiosRequestConfig<SearchContactReqType>,
): AxiosPromise<SearchContactResponseType> => {
  return apis.get(`${search_contacts_new}_2`, payload);
};

export type CreateLeadContactReqType = Partial<{
  headers: {
    Authorization: string;
  };
  contactName: string;
  contactEmail: string;
  contactNumber: string;
  contactAddress: string[];
  contactPersonType: string;
  notes: string;
  pipeline: string;
  linked_partner_id: number;
}>;

export type CreateLeadContactResponseType = Partial<{}>;

export const createLeadContact = (
  payload: AxiosRequestConfig<CreateContactReqType>,
): AxiosPromise<CreateContactResponseType> => {
  return apis.post(create_contact, payload);
};

export type GetLeadReqType = Partial<{
  headers: {
    Authorization: string;
  };
  type: string;
  searchQuery: string;
  productType: string;
  product_id: string[];
  channel_of_interaction_id: string[];
  status: string[];
}>;

export type GetLeadResType = Partial<{}>[];

export const GetLeads = (
  payload: AxiosRequestConfig<GetLeadReqType>,
): AxiosPromise<GetLeadResType> => {
  return apis.get(get_leads, payload);
};

export type CreateLeadOrderReqType = Partial<{
  headers: {
    Authorization: string;
  };
  opportunity_id: string;
  order: {
    invoice_ID: string;
    invoice_Date: string;
    amount: string;
    notes: string;
  };
}>;

export type CreateLeadOrderResType = Partial<{}>;

export const CreateLeadOrder = (
  payload: AxiosRequestConfig<CreateLeadOrderReqType>,
): AxiosPromise<CreateLeadOrderResType> => {
  return apis.patch(create_order, payload);
};

export type GenerateSummaryReqType = Partial<{
  headers: {
    Authorization: string;
  };
  lead_id: string;
}>;

export type GenerateSummaryResType = Partial<{}>;

export const GenerateSummary = (
  payload: AxiosRequestConfig<GenerateSummaryReqType>,
): AxiosPromise<GenerateSummaryResType> => {
  return apis.post(generate_summary, payload);
};

export type PostReqDocReqType = Partial<{
  headers: {
    Authorization: string;
  };
  product_id: string;
  branch_id: string;
  staff_id: string;
  channel_of_interaction_id: string;
  note: string;
  expire_time: string;
  staff_refferal: string;
  partner_refferal: string;
  outlet_branch: string;
  forex_branch: string;
  doc_list: { filename: string }[];
}>;

export type PostReqDocResponseType = Partial<{}>;

export const postReqDoc = (
  payload: AxiosRequestConfig<PostReqDocReqType>,
): AxiosPromise<PostReqDocResponseType> => {
  return apis.post(post_req_doc, payload);
};

export type GetFilteredLeadsReqType = Partial<{
  headers: {
    Authorization: string;
  };
  type: string;
  pipeline: string;
  product_id: string[];
  channel_of_interaction_id: string[];
  isOpen: string[];
  searchQuery: string;
}>;

export type GetFilteredLeadsResponseType = Partial<{}>;

export const getFilteredLeads = (
  payload: AxiosRequestConfig<GetFilteredLeadsReqType>,
): AxiosPromise<GetFilteredLeadsResponseType> => {
  return apis.get(filtered_leads, payload);
};

export type ClearReminderReqType = Partial<{
  headers: {
    Authorization: string;
  };
  opportunity_id: string | number;
}>;

export type ClearReminderResponseType = Partial<{}>;

export const clearReminderPatch = (
  payload: AxiosRequestConfig<ClearReminderReqType>,
): AxiosPromise<ClearReminderResponseType> => {
  return apis.patch(clearReminder, payload);
};

export type GetContactsListReqType = Partial<{
  headers: {
    Authorization: string;
  };
}>;

export type GetContactsListResponseType = Partial<{}>;

export const getContactsList = (
  payload: AxiosRequestConfig<GetContactsListReqType>,
): AxiosPromise<GetContactsListResponseType> => {
  return apis.get(get_contactslist, payload);
};

// partner contact lsit
export type GetContactsPartnerListReqType = Partial<{
  headers: {
    Authorization: string;
  };
}>;
export type Contact = {
  id: string;
  contactName: string;
};

export type GetContactsPartnerListResponseType = {
  data: Contact[];
};

export const getContactsPartnerList = (
  payload: AxiosRequestConfig<GetContactsPartnerListReqType>,
): AxiosPromise<GetContactsPartnerListResponseType> => {
  return apis.get(get_partnerContactList, payload);
};

export type PostAddInteractionsReqType = Partial<{
  headers: {
    Authorization: string;
  };
  channel_of_interaction_id: string | number;
  contact: string;
  meeting_time: Date;
  meetingStaff_id: number[];
  note: string;
  quotation_pdf: string;
  partner_id: string;
  selfie_image: string;
  geo_coordinates: {
    type: string;
    data: {
      lng: string;
      lat: string;
    };
  };
}>;

export type PostAddInteractionsResponseType = Partial<{}>;

export const postAddInteractions = (
  payload: AxiosRequestConfig<PostAddInteractionsReqType>,
): AxiosPromise<PostAddInteractionsResponseType> => {
  return apis.post(post_add_interactions, payload);
};

export type CorpAddInteractionsReqType = Partial<{
  headers: {
    Authorization: string;
  };
  channel_of_interaction_id: string | number;
  contact: string;
  meeting_time: Date;
  meetingStaff_id: number[];
  note: string;
  quotation_pdf: string;
  company_id: string;
  selfie_image: string;
  geo_coordinates: {
    type: string;
    data: {
      lng: string;
      lat: string;
    };
  };
}>;

export type CorpAddInteractionsResponseType = Partial<{}>;

export const corpAddInteractions = (
  payload: AxiosRequestConfig<CorpAddInteractionsReqType>,
): AxiosPromise<CorpAddInteractionsResponseType> => {
  return apis.post(corp_add_interaction, payload);
};

export type PatchLinkPartnerReqType = Partial<{
  headers: {
    Authorization: string;
  };
  partner_id: string;
  contacts_id: string;
  relationship_with_partner: string;
}>;

export type PatchLinkPartnerResponseType = Partial<any>;

export const patchLinkPartner = (
  payload: AxiosRequestConfig<PatchLinkPartnerReqType>,
): AxiosPromise<PatchLinkPartnerResponseType> => {
  return apis.patch(patch_link_partner, payload);
};

export type PatchLinkCorporateReqType = Partial<{
  headers: {
    Authorization: string;
  };
  coorporate_company_id: string;
  contacts_id: string;
  relationship_with_company: string;
}>;

export type PatchLinkCorporateResponseType = Partial<any>;

export const patchLinkCorporate = (
  payload: AxiosRequestConfig<PatchLinkCorporateReqType>,
): AxiosPromise<PatchLinkCorporateResponseType> => {
  return apis.patch(patch_link_corporate, payload);
};

export type PostCorporateLeadReqType = Partial<{
  headers: {
    Authorization: string;
  };
  name: string;
  type: "SMB" | "enterprise";
  website: string;
  state: string;
  city: string;
  services: string[];
  rm_id: string;
  channel_of_interaction_id: string;
  meeting_staff: string[];
  contacted_with: string;
  service: string[];
  meeting_time: string;
  branch: string;
  note: string;
}>;

export type PostCorporateLeadResponseType = Partial<{}>;

export const postCorporateLead = (
  payload: AxiosRequestConfig<PostCorporateLeadReqType>,
): AxiosPromise<PostCorporateLeadResponseType> => {
  return apis.post(post_corporate_lead, payload);
};
