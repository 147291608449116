import { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { createExistingContactSteps as CECS } from "utils";
import ExistingContactDetail from "./step1";
import ExistingOpportunityDetail from "./step2";
import ExistingInteractionDetail from "./step3";
import { step1SchemaExisting } from "pages/Create Opportunities/schema";
import { z } from "zod";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import axios, { AxiosRequestConfig } from "axios";
import {
  createLeadContact,
  CreateLeadContactReqType,
  postExistingOpportunity,
  PostExistingOpportunityReqType,
} from "service/apis/Opportunities";
import { toast, Toaster } from "sonner";
import { resetState } from "redux/features/CreateOpportunity";
import { Spinner } from "components";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import LinkPartner from "models/LinkPartner";
import LinkCorporate from "models/LinkCorporate";
import { checkLevel } from "common/rules";
import { LEVEL_2 } from "common/constants";

interface OpportunitiesSheetProps {
  company_id?: string;
  handleSheetOpen?: (open: boolean) => void;
  isOpen?: boolean;
  type?: string;
}

export default function OpportunitiesSheet({
  company_id,
  handleSheetOpen,
  isOpen,
  type,
}: OpportunitiesSheetProps) {
  const [currentStep, setCurrentStep] = useState(1);
  const [contactID, setContactID] = useState();
  const [iscreateLead, setIsCreateLead] = useState(false); // temporary solution to not tcallthe createContact api again adn again by clicking on the link partner component because its wrapped in a button
  const [linkedPartnerId, setLinkedPartnerId] = useState<string | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState<string[]>([]);
  const [expires, setExpires] = useState("1");
  const [channel, setChannel] = useState("whatsapp");
  const [popupnotes, setPopupNotes] = useState<string>("");
  const { id } = useParams();
  const {
    product_list,
    remit_note,
    country_code,
    customer_contact_email,
    who_contacted,
    medium_of_contact,
    pipelineType,
    contact_id,
    refferal_campaign,
    refferal_source,
    partner_reffered,
    employee_reffered,
    company_address,
    company_email,
    company_name,
    company_phone,
    contact_person_name,
    contact_person_phone,
    contact_person_type,
  } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handlePrevious = () => {
    if (currentStep === 1) return;
    setCurrentStep((prev) => prev - 1);
    setErrors({});
  };

  const validateStep = (step: number) => {
    let isValid = false;
    const newErrors: Record<string, string> = {};

    // if (pipelineType === "retail" || pipelineType === "partner") {
    //   isValid = true;
    //   return isValid;
    // }

    switch (step) {
      case 1:
        try {
          step1SchemaExisting.parse({
            contact_person_name,
            contact_person_phone,
            contact_person_type,
            contact_person_email: customer_contact_email.length
              ? customer_contact_email
              : undefined,
          });
          isValid = true;
        } catch (error) {
          if (error instanceof z.ZodError) {
            error.errors.forEach((err) => {
              newErrors[err.path[0]] = err.message;
            });
          }
        }
        break;
      // Add cases for other steps if needed
      // case 2:
      //     // Validate step 2
      //     break;
      // case 3:
      //     // Validate step 3
      //     break;
      default:
        isValid = true; // Default to true for steps without validation
    }

    setErrors(newErrors);
    return isValid;
  };
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const showLinkPartnerButton = params.get("source") === "new-partner-lead";

  const buttonState = () => {
    // if (pipelineType === "retail" || pipelineType === "partner") {
    //   switch (currentStep) {
    //     case 1:
    //       if (!product_list.length) return false;
    //       return true;
    //     case 2:
    //       if (!medium_of_contact) return false;
    //       return true;
    //     default:
    //       return false;
    //   }
    // }
    switch (currentStep) {
      case 1:
        if (!contact_person_name || !contact_person_phone) {
          return false;
        }
        return true;
      case 2:
        if (!product_list.length) return false;
        return true;
      case 3:
        if (!medium_of_contact || !who_contacted) return false;
        return true;
      default:
        return false;
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <ExistingContactDetail errors={errors} />;
      case 2:
        return <ExistingOpportunityDetail errors={errors} />;
      case 3:
        return <ExistingInteractionDetail errors={errors} />;
      default:
        return null;
    }
  };

  const retailExistingSteps = [
    {
      name: "Opportunity Details",
      step: 1,
    },
    {
      name: "Interaction Details",
      step: 2,
    },
  ];

  const createExistingContactSteps =
    pipelineType === "retail" || pipelineType === "partner"
      ? retailExistingSteps
      : CECS;

  const handleSubmit = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const contact_id = company_id;
    const partner_id = company_id;

    const formData = new FormData();
    switch (pipelineType) {
      case "retail":
        formData.append("contact_id", contact_id as string);
        formData.append("typeOfRefferal", refferal_source);
        formData.append("campaign", refferal_campaign);
        formData.append("refferedBy_staff", employee_reffered);
        formData.append("refferedBy_partner", partner_reffered);
        formData.append("pipelineType", "retail");
        formData.append("exp_time", expires);
        formData.append("note", popupnotes);
        selectedMode.forEach((mode) => {
          formData.append("doc_list[]", JSON.stringify({ filename: mode }));
        });
        formData.append("channel", channel);
        break;
      case "corporate":
        formData.append("contactName", contact_person_name);
        formData.append("contactNumber", contact_person_phone);
        formData.append("contactPersonType", contact_person_type);
        formData.append("coorporate_company_id", company_id as string);
        formData.append("pipelineType", "coorporate");
        break;
      case "partner":
        formData.append("contact_id", partner_id as string);
        formData.append("pipelineType", "partner");

        break;
      default:
        break;
    }

    formData.append("source", medium_of_contact);
    product_list.forEach((product) => {
      formData.append("items[]", JSON.stringify(product));
    });

    const payload: AxiosRequestConfig<PostExistingOpportunityReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: formData as any,
    };

    try {
      const res = await postExistingOpportunity(payload);
      if (res.status === 200) {
        toast.success("Opportunity Created Successfully");
        setLoading(false);
      }
      dispatch(resetState());
      handleSheetOpen?.(!isOpen);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Failed to create opportunity");
    }
  };

  const handdleCreateContact = async (type: string, partnerId?: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (!auth) {
      toast.error("Authentication token not found");
      setLoading(false);
      return null;
    }
    const authToken = JSON.parse(auth);

    const data: CreateLeadContactReqType = {
      contactName: contact_person_name,
      contactNumber: contact_person_phone,
      contactEmail: customer_contact_email,
      contactAddress: [company_address],
      contactPersonType: "individual",
      notes: remit_note,
      pipeline: pipelineType,
      ...(partnerId ? { linked_partner_id: Number(partnerId) } : {}),
    };

    const axiosConfig: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: data,
    };

    try {
      const response = await createLeadContact(axiosConfig);
      if (response.status === 200 || response.status === 201) {
        const newContactId = response?.data?.id;
        setContactID(newContactId);
        setIsCreateLead(true);
        toast.success("Contact created successfully");
        return newContactId;
      }
      const errorMessage =
        response?.data?.message || "Failed to create contact";
      return null;
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.payload ||
        error.message ||
        "Failed to create contact";
      toast.error(errorMessage);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async (type: string) => {
    if (validateStep(currentStep) && !iscreateLead) {
      const newContactId = await handdleCreateContact(type);
      if (newContactId) {
        setErrors({});
        setContactID(newContactId);
      }
    }
  };

  const handleNextProduct = async (type: string) => {
    if (validateStep(currentStep)) {
      try {
        const newContactId = await handdleCreateContact(type);
        if (newContactId) {
          setErrors({});
          navigate("/lead/createlead", {
            state: {
              id: newContactId,
              contact_name: contact_person_name,
              contact_number: contact_person_phone,
              contact_email: customer_contact_email,
            },
          });
        } else {
          toast.error("Failed to create contact");
        }
      } catch (error) {
        console.error("Error creating contact:", error);
        toast.error("Error creating contact");
      }
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="p-3 sm:p-2 flex flex-col font-inter h-full items-start justify-start mx-auto w-full">
        <div className="gap-8 w-full flex h-full flex-col">
          <div className=" flex justify-between w-full items-center border-b gap-2 px-5 pb-4">
            <h3 className=" font-semibold capitalize leading-4 text-lg text-black-label">
              Add Contact
            </h3>
            <AlertDialog>
              <AlertDialogTrigger>
                <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                  Cancel
                </button>
              </AlertDialogTrigger>
              <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                  <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                    <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                      Are you absolutely sure?
                    </AlertDialogTitle>
                    <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                      This action cannot be undone. This will permanently cancel
                      the ongoing progress.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                    <AlertDialogCancel
                      className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                      asChild
                    >
                      <button>Close</button>
                    </AlertDialogCancel>
                    <AlertDialogAction
                      className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                      asChild
                    >
                      <button
                        className=""
                        onClick={() => {
                          dispatch(resetState());
                          if (type === "partner") {
                            navigate("/lead/contacts/partner");
                          } else if (type === "corporate") {
                            navigate("/lead/contacts/corporate");
                          } else {
                            navigate("/lead/contacts");
                          }
                        }}
                      >
                        Confirm
                      </button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogPortal>
            </AlertDialog>
          </div>
          <div className="h-full flex overflow-auto flex-col justify-between gap-5 w-full sm:px-0 px-5">
            {/* <Stepper
                            steps={createExistingContactSteps.map((val) => val.name)}
                            currentStep={currentStep - 1}
                        /> */}
            <div className="flex relative overflow-auto h-full flex-col gap-5 w-full">
              {renderStep()}
            </div>
            <div className="flex sm:pl-0 pl-8 sm:flex-col sm:justify-start gap-3">
              <button
                type="button"
                disabled={!buttonState()}
                onClick={() => {
                  handleNext("partial");
                  navigate("/lead/contacts");
                }}
                className={`px-4 py-[11px] w-fit  rounded-lg disabled:opacity-40 text-blue-700 ${
                  true ? "" : "opacity-40"
                }`}
              >
                Save & Close
              </button>

              {(() => {
                let buttonContent;
                let isCheckLevel = checkLevel(LEVEL_2);
                if (type === "corporate") {
                  buttonContent = (
                    <button
                      type="button"
                      disabled={!buttonState()}
                      className={`px-4 py-[11px] w-fit border border-blue-700 rounded-lg disabled:opacity-40 text-blue-700 ${true ? "" : "opacity-40"}`}
                    >
                      <LinkCorporate
                        contact_name={contact_person_name}
                        onDone={() => {}}
                        showLinkPartnerButton={true}
                        handleSubmit={(partnerId) => {
                          setLinkedPartnerId(partnerId);
                        }}
                        otherProps={{ contact_id: contactID }}
                        onClick={async () => {
                          if (!iscreateLead) {
                            const newContactId =
                              await handdleCreateContact("newlead");
                            if (newContactId) {
                              setErrors({});
                              setContactID(newContactId);
                            }
                          }
                        }}
                      >
                        <span>Link corporate</span>
                      </LinkCorporate>
                    </button>
                  );
                } else if (type === "partner") {
                  buttonContent = (
                    <button
                      type="button"
                      disabled={!buttonState()}
                      className={`px-4 py-[11px] w-fit border border-blue-700 rounded-lg disabled:opacity-40 text-blue-700 ${true ? "" : "opacity-40"}`}
                    >
                      <LinkPartner
                        contact_name={contact_person_name}
                        onDone={() => {}}
                        showLinkPartnerButton={true}
                        handleSubmit={(partnerId) => {
                          setLinkedPartnerId(partnerId);
                        }}
                        otherProps={{ contact_id: contactID }}
                        onClick={async () => {
                          if (!iscreateLead) {
                            const newContactId =
                              await handdleCreateContact("newlead");
                            if (newContactId) {
                              setErrors({});
                              setContactID(newContactId);
                            }
                          }
                        }}
                      >
                        <span>Link partner</span>
                      </LinkPartner>
                    </button>
                  );
                } else {
                  if (type === "multi" && isCheckLevel) return null;
                  buttonContent = (
                    <>
                      <button
                        type="button"
                        disabled={!buttonState()}
                        className={`px-4 py-[11px] w-fit border border-blue-700 rounded-lg disabled:opacity-40 text-blue-700 ${true ? "" : "opacity-40"}`}
                      >
                        <LinkCorporate
                          contact_name={contact_person_name}
                          onDone={() => {}}
                          showLinkPartnerButton={true}
                          handleSubmit={(partnerId) => {
                            setLinkedPartnerId(partnerId);
                          }}
                          otherProps={{ contact_id: contactID }}
                          onClick={async () => {
                            if (!iscreateLead) {
                              const newContactId =
                                await handdleCreateContact("newlead");
                              if (newContactId) {
                                setErrors({});
                                setContactID(newContactId);
                              }
                            }
                          }}
                        >
                          <span>Link corporate</span>
                        </LinkCorporate>
                      </button>
                      <button
                        type="button"
                        disabled={!buttonState()}
                        className={`px-4 py-[11px] w-fit border border-blue-700 rounded-lg disabled:opacity-40 text-blue-700 ${true ? "" : "opacity-40"}`}
                      >
                        <LinkPartner
                          contact_name={contact_person_name}
                          onDone={() => {}}
                          showLinkPartnerButton={true}
                          handleSubmit={(partnerId) => {
                            setLinkedPartnerId(partnerId);
                          }}
                          otherProps={{ contact_id: contactID }}
                          onClick={async () => {
                            if (!iscreateLead) {
                              const newContactId =
                                await handdleCreateContact("newlead");
                              if (newContactId) {
                                setErrors({});
                                setContactID(newContactId);
                              }
                            }
                          }}
                        >
                          <span>Link partner</span>
                        </LinkPartner>
                      </button>
                    </>
                  );
                }
                return buttonContent;
              })()}

              <button
                type="button"
                disabled={!buttonState()}
                onClick={() => handleNextProduct("newlead")}
                className={`px-4 py-[11px] w-fit rounded-lg border ${
                  true
                    ? "border-blue-700 bg-blue-700 text-white-A700"
                    : "bg-blue-700 text-white-A700"
                } disabled:opacity-40`}
              >
                Select Product
              </button>

              {/* <button
                type="button"
                disabled={!buttonState()}
                onClick={() => handleNext("add")}
                className={`px-4 py-[11px] w-fit rounded-lg border ${false ? "border-blue-700 text-blue-700" : "bg-blue-700 text-white-A700"} disabled:opacity-40`}
              >
                Add another
              </button> */}
              {/* {pipelineType==='retail' && currentStep===2 && <Separator className="bg-gray-border-gray-lite sm:hidden" orientation='vertical' />}
                            {
                                 pipelineType === 'retail' && currentStep === 2  &&
                                <DocPopup buttonState={buttonState} onSubmit={handleSubmit} selectedMode={selectedMode} setSelectedMode={setSelectedMode} expires={expires} setExpires={setExpires} popupnotes={popupnotes} setPopupNotes={setPopupNotes} channel={channel} setChannel={setChannel} />                                                                   
                            } */}
            </div>
          </div>
        </div>
        <Toaster position="top-right" theme="dark" />
      </div>
    </>
  );
}
