// import { Navigate, useLocation } from "react-router-dom";

// import Cookies from "universal-cookie";

// const cookies = new Cookies();

// type Props = {
//   element: React.FC;
// };

// export const ProtectedRoute: React.FC<Props> = ({ element: Element }) => {
//   const { pathname } = useLocation();
//   const authtoken = cookies.get("authToken");
//   const isEmployee = localStorage.getItem("isEmployee") !== "true";
//   const level = localStorage.getItem("level");

//   const unprotectedPaths = ["/", "/forgetpassword", "/setpassword"];
//   const OwnerPaths = ["/staff", "/roleandgroups", "/approvals"];
//   const FinancePaths = ["/finance"];

//   // Check if the current path is in the unprotectedPaths array
//   if (!unprotectedPaths.includes(pathname) && !authtoken) {
//     return <Navigate to="/" />;
//   }
//   // Check if the current path is access to employee or not
//   if (isEmployee && OwnerPaths.includes(pathname)) {
//     return <Navigate to="/" />;
//   }
//   if(level !== "1" && FinancePaths.includes(pathname)){
//     return <Navigate to="/" />;
//   }

//   return <Element />;
// };

import { LEVEL_2 } from "common/constants";
import { checkLevel } from "common/rules";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

type Props = {
  element: React.ReactNode | React.ComponentType;
};

export const ProtectedRoute: React.FC<Props> = ({ element }) => {
  const { pathname } = useLocation();
  const authtoken = cookies.get("authToken");
  const isOwner = localStorage.getItem("is_owner");
  const level = localStorage.getItem("level");
  const channel_id = localStorage.getItem("channel_id");

  const unprotectedPaths = ["/", "/forgetpassword", "/setpassword"];
  const OwnerPaths = ["/staff", "/roleandgroups", "/approvals"];
  const FinancePaths = ["/finance"];

  const ServicePath = [
    "/leads/forex",
    "/leads/visa",
    "/leads/travel",
    "/lead/contacts/partner",
    "/lead/contacts/corporate",
    "/leads",
    "/accessandroles",
    "/staff",
    "/group",
    "leads/corporate/*",
  ];
  const disabledPaths = [""];

  // Check if the current path is in the disabledPaths array
  if (disabledPaths.includes(pathname)) {
    return <Navigate to="/" />;
  }

  if (channel_id !== "2" && pathname === "/accessandroles") {
    return <Navigate to="/" />;
  }

  // Check if the current path is in the unprotectedPaths array
  if (!unprotectedPaths.includes(pathname) && !authtoken) {
    return <Navigate to="/" />;
  }
  // Check if the current path is access to employee or not
  if (!isOwner && OwnerPaths.includes(pathname)) {
    return <Navigate to="/" />;
  }

  if (checkLevel(LEVEL_2) && ServicePath.includes(pathname)) {
    return <Navigate to="/" />;
  }

  // Check if element is a React component (imported file)
  if (typeof element === "function") {
    const Component = element;
    return <Component />;
  }

  // If element is a React node
  return element as React.ReactElement;
};
