import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { PaymentType } from "@/lib/validations/schema";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { AxiosRequestConfig } from "axios";
import { order_status_options } from "common/filters";
import { Img, Spinner, Text } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import UploadDocuments from "models/OrderDetails/CaptureDocuments";
import AddDeclaration from "models/OrderDetails/CapturePayment";
import SettlementRate from "models/OrderDetails/SettlementRate";
import { convertFileToBase64 } from "pages/CreateRemit/payment";
import { formatUnixTimestamp } from "pages/Opportunities/utils/featureRule";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AddOrderNotesAPI,
  POSTAddNotesOrderRequestType,
} from "service/apis/CreateOrderapi";
import {
  CapturePaymentRequestType,
  GetPartnerDetailsRequestType,
  PutOrderStatusRequestType,
  capturePayment,
  getDocuments,
  getPartnerDetails,
  putOrderStatus,
} from "service/apis/Orderdetails";
import { Toaster, toast } from "sonner";
import { formatCurrency, formatIndianNumber } from "utils";
import PaymentReviewModal from "./paymentReviewSection";
import { Input } from "@/components/ui/input";
import {
  AlertCircle,
  CheckCircle,
  Eye,
  RefreshCw,
  UploadCloud,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import FileUploadDialog from "models/FileUpload";
const inProgressOptionsList = [
  { label: "Fulfilled", value: "fulfilled" },
  { label: "Cancel", value: "cancelled" },
  { value: "pre processing", label: "Pre Processing" },
  { value: "processing", label: "Processing" },
];
type PaymentTableRowType = {
  id: string;
  acknowledgement_no: string;
  amount: string;
  receipt: {
    url: string;
  };
};
type OrderdetailsType = {
  id: string;
  status: string;
  payment_status: string;
  created_at: string;
  country: string;
  type: string;
  note: string;
  note_last_edited: number;
  _order_customers: {
    full_name: string;
    email: string;
    phone: string;
    pan_no: string;
    aadhaar_no: string;
    pan_soft_copy: {
      url: string;
    };
    aadhaar_soft_copy_front: {
      url: string;
    };
  };
  supporting_documents: [];
  _order_channels: {
    id: string;
    name: string;
    phone: string;
    email: string;
    address: string;
  };
  _beneficiary: {
    account_number: string;
    bank_addr: string;
    bank_country: string;
    bank_name: string;
    correspondant_bank_name: string;
    correspondant_bank_no: string;
    iban_no: string;
    id: number;
    name: string;
    swift_code: string;
  };
  partner_detail: {
    city: string;
    id: number;
    name: string;
    state: string;
    type: string;
  };
  amount: number;
  currency: string;
  gst: number;
  tcs: number;
  tt_charges_inclgst: number;
  foreign_bank_rates_inclgst: number;
  opportunity_id: number;
  payable_amount: number;
  l1_commission_rates: number;
  l2_commission_rates: number;
  l3_commission_rates: number;
  l1_channel: number;
  l2_channel: number;
  l3_channel: number;
  service_partner_rates: number;
  eforex_rates: number;
  _payments_of_order: {
    id: string;
    acknowledgement_no: string;
    amount: string;
    receipt?: {
      url: string;
    };
  }[];
  _order_service_partner: {
    id: string;
    display_name: string;
    email: string;
    legal_addr_city: string;
    legal_addr_state: string;
  };
  swift_document: {
    url: string;
  };
  invoice_document: {
    url: string;
  };
  _purpose: {
    id: string;
    name: string;
  };
};

const NewOrderDetailsPage: React.FC = () => {
  const {
    register,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { id } = useParams();
  const param = useParams();
  const [orderdetails, setOrderDetails] = useState<OrderdetailsType>();
  const [createdate, setCreateDate] = useState<any>();
  const [showcapturepayment, setShowCapturePayment] = useState(false);
  const [showUploadDocumentType, setShowUploadDocumentType] = useState("");
  const [showSettlementRate, setShowSettlementRate] = useState(false);
  const [showimg, setShowimg] = useState(false);
  const [imgsrc, setImgsrc] = useState("");
  const [orderstatus, setOrderStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState<number>();
  const [activeTab, setTab] = useState<string>("overview");
  const [paymentData, setPaymentData] = useState<PaymentType[]>([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [dealID, setDealID] = useState<number>();
  const [channelDetails, setChannelDetails] = useState();
  const [studentName, setStudentName] = useState<string>("");
  const [margin, setMargin] = useState({
    l1: "",
    l2: "",
    l3: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState<string>("");
  const [tempNote, setTempNote] = useState<string>(note);
  const [isTCSEditing, setIsTCSEditing] = useState(false);
  const [tcsValue, setTcsValue] = useState<string>(
    orderdetails?.tcs?.toFixed(2) || "",
  );
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isOpen, setIsopen] = useState<boolean>(false);
  const [rowSelected, setRowSelected] = useState("");
  const [verifyDocs, setVerifyDocs] = useState(false);

  const openDetailNote = () => {
    setIsEditing(true);
  };

  const handleSave = async (order_id: Number, note: string) => {
    setNote(tempNote);
    setIsEditing(false);

    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<POSTAddNotesOrderRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        order_id,
        note,
      },
    };
    try {
      const response = await AddOrderNotesAPI(payload);
      if (response?.status === 200) {
        OrderDetails();
        console.log("API resp ====?>>>>", response?.data);
      }
    } catch (error) {
      toast.error("Failed to update partner details");
    } finally {
      setLoading(false);
    }
  };

  const handleTCSCancel = () => {
    setTcsValue("");
    setIsTCSEditing(false);
  };

  // for TCS
  const handleTCSSave = async (
    order_id: number,
    note: string,
    tcs_amount: number,
  ) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<POSTAddNotesOrderRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        order_id,
        note,
        tcs_amount,
      },
    };
    try {
      const response = await AddOrderNotesAPI(payload);
      if (response?.status === 200) {
        OrderDetails();
        setIsTCSEditing(false);
        setTcsValue("0.00");
      }
    } catch (error) {
      toast.error("Failed to update partner details");
    } finally {
      setLoading(false);
    }
  };

  const handleDocReview = async (isReview: boolean) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<POSTAddNotesOrderRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        order_id: Number(id),
        doc_status: isReview ? "Approved" : "Review",
      },
    };
    try {
      const response = await AddOrderNotesAPI(payload);
      if (response?.status === 200) {
        OrderDetails();
        setVerifyDocs(false);
      }
    } catch (error) {
      toast.error("Failed to update partner details");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    OrderDetails();
  }, [id, shouldRefetch]);

  useEffect(() => {
    if (orderdetails) {
      const date = new Date(orderdetails?.created_at);
      setCreateDate(date.toUTCString().substring(0, 16));
    }

    const amount = orderdetails?._payments_of_order.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue.amount),
      0,
    );
    setRemainingAmount(Number(orderdetails?.payable_amount) - Number(amount));
  }, [orderdetails]);

  // useEffect(() => {
  //   if (orderstatus) {
  //     handleonorderstatuschange();
  //   }
  // }, [orderstatus]);

  const level = localStorage.getItem("level");
  const channel_id = localStorage.getItem("channel_id");
  const orderChildLevel =
    orderdetails?.l2_channel == 0
      ? "1"
      : orderdetails?.l3_channel == 0
        ? "2"
        : "3";

  const OrderDetails = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetPartnerDetailsRequestType> = {
      data: {
        order_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getPartnerDetails(payload)
      .then((res: any) => {
        console.log(res.data);
        setDealID(res?.data?.dealID);
        setChannelDetails(res?.data?.channel?.[0]);
        setStudentName(res?.data?.student_name);
        setOrderDetails(res.data?.result_1);
        setNote(res.data?.result_1?.note);
        setMargin({
          l1: res.data?.result_1?.l1_commission_rates.toString(),
          l2: res.data?.result_1?.l2_commission_rates.toString(),
          l3: res.data?.result_1?.l3_commission_rates.toString(),
        });
        console.log(
          "Print value of payment mode ===>>",
          res.data?.result_1?._payments_of_order,
        );
        setPaymentData(
          res.data?.result_1?._payments_of_order.map(
            (payment: any, index: number) => ({
              id: index + 1,
              acknowledgement: payment.acknowledgement_no,
              createdBy: "N/A",
              creationDate: new Date().toDateString(),
              amount: parseInt(payment.amount),
              receipt: payment.receipt,
              paymentMode: "Offline",
            }),
          ) || [],
        );
        if (res.data.result_1.status) {
          setOrderStatus(res.data?.result_1.status);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleonorderstatuschange = (e: string): void => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PutOrderStatusRequestType> = {
      data: {
        status: e,
        order_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);
    putOrderStatus(payload)
      .then((res: any) => {
        setLoading(false);
        setOrderStatus(res.data.status);
        OrderDetails();
        toast.success("Order Status Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const paymentTableColumns: any = useMemo(() => {
    const tableColumnsHelper = createColumnHelper<PaymentTableRowType>();
    return [
      tableColumnsHelper.accessor("id", {
        cell: (info) => (
          <div
            // onClick={() => handleCellClick(info.getValue())}
            className="flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info.row.index + 1}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Ref ID
            </Text>
          </div>
        ),
      }),
      tableColumnsHelper.accessor("acknowledgement_no", {
        cell: (info) => (
          <div
            // onClick={() => handleCellClick(info.getValue())}
            className="flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Acknowledgement No.
            </Text>
          </div>
        ),
      }),
      tableColumnsHelper.accessor("amount", {
        cell: (info) => (
          <div
            // onClick={() => handleCellClick(info.getValue())}
            className="flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {formatIndianNumber(info?.getValue())}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Amount
            </Text>
          </div>
        ),
      }),
      tableColumnsHelper.accessor("receipt", {
        cell: (info) => (
          <div
            onClick={() => {
              setImgsrc(info?.getValue()?.url);
              setShowimg(!showimg);
            }}
            className="cursor-pointer flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              View
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Receipt
            </Text>
          </div>
        ),
      }),
    ];
  }, []);

  const downloadFilefromUrl = async (pdf_url: string, name: string) => {
    try {
      const response = await fetch(pdf_url);
      const blob = await response.blob();

      // const blob = base64toBlob(pdf_url);
      // Create a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name;

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // // Clean up: remove the link
      document.body.removeChild(link);
    } catch (error) {
      // console.error('Error downloading File:', error);
    }
  };

  const status = orderdetails?.payment_status;
  let paymentborderColor;
  switch (status) {
    case "pending":
      paymentborderColor = "bg-[#FFA500]"; // Gray
      break;
    case "partially recieved":
      paymentborderColor = "bg-[#0000FF]"; // Light Green
      break;
    case "recieved":
      paymentborderColor = "bg-[#008000]"; // Green
      break;
    default:
  }
  let statusbgcolor = orderstatus;
  switch (statusbgcolor) {
    case "pending":
      statusbgcolor = "bg-[#808080]"; // Gray
      break;
    case "processing":
      statusbgcolor = "bg-[#32CD32]"; // Light Green
      break;
    case "fulfilled":
      statusbgcolor = "bg-colors1"; // Green
      break;
    case "pre processing":
      statusbgcolor = "bg-[#888888]";
      break;
    case "cancelled":
      statusbgcolor = "bg-[#FF0000]";
      break;
    default:
  }

  const getPaymentStatus = (status: string): string => {
    if (status === "recieved") {
      return "received";
    } else if (status === "partially recieved") {
      return "partially received";
    }
    return status;
  };

  const tabsdata = [
    {
      id: 1,
      label: "Overview",
      value: "overview",
      content: "Make changes to your account here.",
    },
    {
      id: 2,
      label: "Amount & Charges",
      value: "amount-charges",
      content: "Change your password here.",
    },
    {
      id: 3,
      label: "Documentations",
      value: "documents",
      content: "Change your password here.",
    },
    {
      id: 4,
      label: "Payments",
      value: "payments",
      content: "Change your password here.",
    },
    {
      id: 5,
      label: "Other Details",
      value: "other-details",
      content: "Change your password here.",
    },
  ];
  const columns: ColumnDef<PaymentType>[] = [
    // {
    //   accessorKey: "id",
    //   header: ({ column }) => (
    //     <DataTableColumnHeader column={column} title="Ref ID" />
    //   ),
    // },
    {
      accessorKey: "acknowledgement",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Acknowledgement" />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-2">
            <p className="font-normal capitalize text-sm leading-[17px] text-black-label">
              {row?.original?.acknowledgement || "N/A"}
            </p>
            <p className="font-normal capitalize text-sm leading-[14.5px] text-[#999999]">
              RID - {row?.original?.id || "N/A"}
            </p>
          </div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "creationDate",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Date Created" />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-2">
            <p className="font-normal capitalize text-sm leading-[17px] text-black-label">
              {row?.original?.creationDate || "N/A"}
            </p>
            <p className="font-normal capitalize text-sm leading-[14.5px] text-[#999999]">
              Created By- {row?.original?.createdBy || "N/A"}
            </p>
          </div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "amount",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Amount" />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-2">
            <p className="font-normal capitalize text-sm leading-[17px] text-black-label">
              {`INR ${row?.original?.amount}` || "N/A"}
            </p>
          </div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "paymentMode",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Payment Mode" />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "Status",
      cell: ({ row }) => {
        const receiptUrl = row.original.receipt;
        return (
          <div className="flex flex-col gap-2">
            {row?.original?.paymentMode === "Offline" ? (
              <button
                style={{
                  display: "inline-block",
                  padding: "10px 20px",
                  backgroundColor: "inherit",
                  color: "#333333",
                  textDecoration: "none",
                  borderRadius: "5px",
                  border: "1px solid #333333",
                  fontWeight: "bold",
                  textAlign: "center",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => setIsopen(true)}
              >
                Review
              </button>
            ) : (
              <>
                <p
                  style={{
                    backgroundColor: order_status_options.find(
                      (status) => orderdetails?.status === status.value,
                    )?.bgColor,
                    color: order_status_options.find(
                      (status) => orderdetails?.status === status.value,
                    )?.textColor,
                  }}
                  className="py-[4px] text-sm leading-4 capitalize font-medium  px-2 text-center rounded-md"
                >
                  {orderdetails?.payment_status}
                </p>
              </>
            )}
          </div>
        );
      },
    },
  ];
  const labelStyle = "font-medium text-[#666666] text-sm leading-[21px]";
  const valueStyle = "font-medium text-[#333333] text-sm leading-[21px]";

  const onTabChange = (value: string) => {
    if (value === "documents") {
      fetchDocuments();
    }
    setTab(value);
  };

  const onSaveClick = () => {
    const formData = getValues();
    convertFileToBase64(formData["supporting-document"][0])
      .then((base64String) => {
        handleCapturePayment(formData, base64String)
          .then((res) => {
            setShouldRefetch(!shouldRefetch);
            reset();
            setSelectedFile(null);
            console.log(res);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error("Error converting file to base64:", error);
      });
  };

  const handleCapturePayment = async (formData: any, file: string) => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        acknowledgement_no: formData.acknowledgement_no,
        amount: formData.amount,
        receipt: file,
        order_id: id,
      } as CapturePaymentRequestType,
    };
    try {
      await capturePayment(payload);
      toast.success("Payment Captured Successfully");
      setDialogOpen(false);
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleSwiftDocument = () => {
    if (
      orderdetails?.status === "fulfilled" &&
      orderdetails?.swift_document?.url
    ) {
      downloadFilefromUrl(orderdetails?.swift_document.url, "swift_document");
      return;
    }
    setShowUploadDocumentType("SWIFT");
  };

  const handleInvoiceDocument = () => {
    if (
      orderdetails?.status === "fulfilled" &&
      orderdetails?.invoice_document?.url
    ) {
      downloadFilefromUrl(
        orderdetails?.invoice_document.url,
        "invoice_document",
      );
      return;
    }
    setShowUploadDocumentType("Invoice");
  };

  const fetchDocuments = async () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        order_id: id,
      },
    };
    try {
      const response = await getDocuments(payload);
      console.log(response);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  // calculate balance
  const calculateTotalAmount = (payments: any[] = []) => {
    return payments.reduce(
      (total, payment) => total + (payment.amount || 0),
      0,
    );
  };
  const totalAmount = calculateTotalAmount(orderdetails?._payments_of_order);
  const balance =
    (Number(orderdetails?.payable_amount.toFixed(0)) || 0) - totalAmount;

  // customer rate
  const serviceRate = Number(orderdetails?.service_partner_rates) || 0;
  const marginLevel1 = Number(margin.l1) || 0;
  const marginLevel2 = Number(margin.l2) || 0;
  const marginLevel3 = Number(margin.l3) || 0;

  const totalMargin =
    Number(level) === 1
      ? marginLevel1
      : Number(level) === 2
        ? marginLevel1 + marginLevel2
        : marginLevel1 + marginLevel2 + marginLevel3;

  const totalValue = (serviceRate + totalMargin).toFixed(2);

  const StepIndicator = ({ orderdetails, margin, level }) => {
    const serviceRate = Number(orderdetails?.service_partner_rates) || 0;
    const marginLevel1 = Number(margin.l1) || 0;
    const marginLevel2 = Number(margin.l2) || 0;
    const marginLevel3 = Number(margin.l3) || 0;

    const totalMargin =
      Number(level) === 1
        ? marginLevel1
        : Number(level) === 2
          ? marginLevel1 + marginLevel2
          : marginLevel1 + marginLevel2 + marginLevel3;

    const totalValue = (serviceRate + totalMargin).toFixed(2);

    const steps = [
      { label: "Customer rate", value: `INR ${totalValue}` },
      { label: "Partner rate", value: `INR ${serviceRate}` },
      { label: "Settlement rate", value: `INR ${serviceRate}` },
    ];

    return (
      <div className="flex flex-col gap-2 py-4">
        {steps.map((item, index) => (
          <div key={index} className="relative flex items-start gap-4">
            {/* Step Indicator with Connecting Line */}
            <div className="relative flex flex-col items-center">
              <img
                className="w-3 h-3"
                src="/images/overview/ellipse.svg"
                alt="Step"
              />
              {index < steps.length - 1 && (
                <div className="w-[2px] h-20 bg-blue-700 border-l-2 border-dashed" />
              )}
            </div>

            {/* Step Content */}
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium leading-[17px]">
                {item.label}: {item.value}
              </span>

              {/* Partner Margin */}
              {index === 0 && (
                <div className="p-3 mt-2 bg-gray-100 rounded-lg">
                  <span className="text-sm text-[#666666] font-medium leading-[17px]">
                    Partner margin: INR{" "}
                    {level === "1"
                      ? margin.l1
                      : level === "2"
                        ? margin.l2
                        : margin.l3}
                  </span>
                </div>
              )}

              {/* MoneeFlo Margin */}
              {index === 1 && (
                <div className="p-3 mt-2 bg-gray-100 rounded-lg">
                  <span className="text-sm text-[#666666] font-medium leading-[17px]">
                    MoneeFlo margin: INR{" "}
                    {level === "1"
                      ? margin.l1
                      : level === "2"
                        ? margin.l2
                        : margin.l3}
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleAction = (rowData: any) => {
    setRowSelected(rowData?.id);
    if (rowSelected === rowData?.id) {
      // setRowSelected("");
      // dispatch(setBeneficiaryType(""));
      // dispatch(setBeneficiaryName(""));
      // dispatch(setBeneficiaryAccountNumber(""));
      // dispatch(setBeneficiaryBankName(""));
      // dispatch(setBeneficiaryBankAddr(""));
      // dispatch(setBeneficiaryBankCountry(""));
      // dispatch(setBeneficiarySwiftCode(""));
      return;
    }
    // dispatch(setBeneficiaryType(rowData?.beneficiaryType));
    // dispatch(setBeneficiaryName(rowData?.name));
    // dispatch(setBeneficiaryAccountNumber(rowData?.account_number));
    // dispatch(setBeneficiaryBankName(rowData?.bank_name));
    // dispatch(setBeneficiaryBankAddr(rowData?.bank_addr));
    // dispatch(setBeneficiaryBankCountry(rowData?.country));
    // dispatch(setBeneficiarySwiftCode(rowData?.swift_code));
  };

  return (
    <>
      {loading && <Spinner />}
      {
        <div>
          {showcapturepayment && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <AddDeclaration
                orderID={id}
                setShowCapturePayment={setShowCapturePayment}
                OrderDetails={OrderDetails}
                remainingAmount={remainingAmount}
              />
            </div>
          )}
          {showUploadDocumentType !== "" && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-50 h-screen w-[100vw] flex justify-center items-center"
            >
              <UploadDocuments
                showUploadDocumentType={showUploadDocumentType}
                orderID={id}
                setShowUploadDocumentType={setShowUploadDocumentType}
                OrderDetails={OrderDetails}
              />
            </div>
          )}
          {showSettlementRate && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <SettlementRate
                setShowSettlementRate={setShowSettlementRate}
                id={id}
                orderdetails={orderdetails}
                OrderDetails={OrderDetails}
              />
            </div>
          )}
        </div>
      }

      {showimg && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Img src={imgsrc} className="h-[300px] w-[400px]" alt="Loading...." />
          <div
            className="relative top-[-150px] left-[10px]"
            onClick={() => setShowimg(!showimg)}
          >
            <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
          </div>
        </div>
      )}

      <div className="flex w-full flex-col gap-5 py-5 px-6 sm:px-3 h-full">
        <div className=" flex flex-col w-full gap-8 border-b-2 pb-5">
          {/* <div className=" flex flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              Order
            </h3>
            <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
              Comprehensive details of Order-{id}
            </p>
          </div> */}
          <div className="flex justify-between items-center">
            {/* <div className="py-2 px-3 bg-black-900 rounded-lg w-fit">
              <div className="flex gap-2">
                <p className="text-white-A700 bg-black-900 ">
                  Order ID:{" "}
                  <span className="font-medium text-[18px] leading-[14px]">
                    {orderdetails?.id}
                  </span>
                </p>
                <p
                  style={{
                    backgroundColor: order_status_options.find(
                      (status) => orderdetails?.status === status.value,
                    )?.bgColor,
                    color: order_status_options.find(
                      (status) => orderdetails?.status === status.value,
                    )?.textColor,
                  }}
                  className=" capitalize py-[4px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
                >
                  {orderdetails?.status}
                </p>
              </div>
            </div> */}

            <div className=" flex flex-col gap-2">
              <div className="flex flex-row items-center gap-2">
                <h3 className="font-semibold leading-4 text-lg text-black-label">
                  {orderdetails?._order_customers?.full_name}
                </h3>
                <span className="text-gray-400">•</span>
                <h3 className="font-semibold leading-4 text-lg text-black-label">
                  {orderdetails?.type}
                </h3>
                <span className="text-gray-400">•</span>
                <span className="text-gray-500">Order ID : {id}</span>
              </div>

              <div>
                <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
                  {orderdetails?.created_at &&
                    new Date(orderdetails.created_at)
                      .toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })
                      .replace(",", "")
                      .replace(" at", "")}{" "}
                  by {orderdetails?._order_channels.name} |{" "}
                  {channelDetails?.Channel} {orderdetails?.country}
                </p>
              </div>
            </div>
            {level === "1" &&
              (orderdetails?.payment_status === "recieved" ||
                orderdetails?.status === "fulfilled") && (
                <div className="flex gap-3">
                  <Button
                    onClick={handleSwiftDocument}
                    className="rounded-lg py-[11px] flex gap-2 px-4 text-[#165FE3] font-semibold text-sm"
                  >
                    {orderdetails?.status === "fulfilled" &&
                    orderdetails?.swift_document?.url
                      ? "Download SWIFT"
                      : "Upload SWIFT"}
                    <img
                      alt="upload icon"
                      src="/images/remit/uploadIcon.svg"
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                  <Button
                    onClick={handleInvoiceDocument}
                    className="rounded-lg py-[11px] px-4 text-[#165FE3] flex gap-2 font-semibold text-sm"
                  >
                    {orderdetails?.status === "fulfilled" &&
                    orderdetails?.invoice_document?.url
                      ? "Download Invoice"
                      : "Upload Invoice"}
                    <img
                      alt="upload icon"
                      src="/images/remit/uploadIcon.svg"
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                </div>
              )}
          </div>
        </div>

        {/* all tabs */}
        <Tabs
          value={activeTab}
          onValueChange={onTabChange}
          className=" sm:max-w-7xl sm:overflow-hidden"
        >
          <TabsList className="border-b border-[#C4D7F9] w-full hide-scrollbar flex justify-between rounded-none gap-2 h-12">
            {tabsdata.map((tab) => (
              <TabsTrigger
                key={tab.id}
                value={tab.value}
                className={`px-6 py-3.5 text-sm ${tab.value === activeTab && "border-t h-11 rounded-none font-semibold border-blue-700 text-blue-700 w-[215px] shadow bg-[#EFF4FF]"}`}
              >
                {tab.label}
              </TabsTrigger>
            ))}
          </TabsList>
          <TabsContent
            className="p-3 gap-10 flex flex-col rounded-xl w-full overflow-auto"
            value="overview"
          >
            {/* <div className="pb-10 border-b w-full flex flex-col gap-8"> */}
            {/* <div className="flex items-center w-full"> */}
            {/* <div className="py-2 px-3  rounded-lg w-fit">
                  <div className="flex gap-2">
                    <p
                      style={{ backgroundColor: "#F3FFF7", color: "#00A82D" }}
                      className="py-2 text-sm leading-4 font-medium flex items-center gap-2 px-3 text-center rounded-xl"
                    >
                      <Img src="/images/orderdetails/green-circle.svg" />
                      Fullfilled
                    </p>
                  </div>
                </div> */}
            {/* <div className="py-2 px-3 rounded-lg w-fit sm:w-full">
                  <div className="flex sm:w-full gap-2">
                    <p
                      style={{ backgroundColor: "#EBF1FB", color: "#165FE3" }}
                      className="py-2 text-sm leading-4 font-medium flex items-center gap-2 px-3 text-center rounded-xl capitalize"
                    >
                      <Img src="/images/orderdetails/blue-circle.svg" />
                      Processing
                    </p>
                  </div>
                </div>
                <div className="sm:w-full">
                  <svg
                    width="144"
                    height="3"
                    viewBox="0 0 144 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="144"
                      y="2.5"
                      width="144"
                      height="2"
                      rx="1"
                      transform="rotate(180 144 2.5)"
                      fill="url(#paint0_linear_1105_32921)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1105_32921"
                        x1="144"
                        y1="3.5"
                        x2="288"
                        y2="3.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop
                          offset="0.467887"
                          stop-color={
                            orderdetails?.status === "processing"
                              ? "#999999"
                              : order_status_options.find(
                                  (status) =>
                                    orderdetails?.status === status.value,
                                )?.textColor
                          }
                        />
                        <stop offset="0.534294" stop-color="#165FE3" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="py-2 px-3  rounded-lg w-fit sm:w-full">
                  <div
                    className={`flex sm:w-full gap-2 ${orderdetails?.status === "processing" ? "grayscale" : ""}`}
                  >
                    <p
                      style={{
                        backgroundColor: order_status_options.find(
                          (status) => orderdetails?.status === status.value,
                        )?.bgColor,
                        color: order_status_options.find(
                          (status) => orderdetails?.status === status.value,
                        )?.textColor,
                      }}
                      className="py-2 text-sm sm:w-full leading-4 font-medium flex items-center gap-2 px-3 text-center rounded-xl"
                    >
                      <Img
                        src="/images/orderdetails/green-circle.svg"
                        className={
                          orderdetails?.status !== "processing"
                            ? "grayscale"
                            : ""
                        }
                      />
                      {orderdetails?.status === "processing"
                        ? "Fullfilled"
                        : orderdetails?.status}
                    </p>
                  </div>
                </div>
              </div> */}
            {/* </div> */}
            {/* <div className="flex justify-between gap-8 w-full"> */}
            {/* <div className="flex w-2/3 flex-col gap-5">
                <div className="bg-[#FCFCFC] p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.22457 3.71649C6.76715 3.12836 6.05276 2.75 5.25 2.75C3.86929 2.75 2.75 3.86929 2.75 5.25C2.75 6.06385 3.13889 6.78687 3.74097 7.24337"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.7734 3.7195C17.2307 3.12966 17.9461 2.75 18.7503 2.75C20.131 2.75 21.2503 3.86929 21.2503 5.25C21.2503 6.06148 20.8637 6.78265 20.2646 7.23937"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 8V12L9 15"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 21.25L7 20M18 21.25L17 20"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <p className="">Created at</p>
                        <p className="">
                          {new Date(
                            orderdetails?.created_at as string,
                          ).toDateString()}
                        </p>
                      </div>
                      <div className="flex gap-5">
                        <p className="">Created by</p>
                        <Link to="#" className="text-blue-700">
                          {orderdetails?._order_channels.name} &#8599;
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-[#FCFCFC] p-5 flex flex-col gap-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <Img src="/images/orderdetails/copy.svg" />
                        <p className="text-blue-700">Document Uploaded</p>
                      </div>
                      <div className="flex pl-10 gap-1">
                        <span className="font-medium text-[40px] leading-9 text-blue-700">
                          {orderdetails?.supporting_documents.length}
                        </span>
                        <span className="font-medium text-xl leading-6 text-[#98BDFF] flex items-end">
                          / {orderdetails?.supporting_documents.length}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={() => setTab("documents")}
                    className=" underline text-blue-700 text-right w-full"
                  >
                    View details
                  </Link>
                </div>
              </div> */}
            {/* <div className="flex flex-wrap gap-5 flex-auto w-full">
                <div className="border-gray-border-gray-lite border flex flex-grow p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5 w-full">
                        <Img src="/images/orderdetails/house.svg" />
                        <p className="text-blue-700">Remitter</p>
                        <p className="text-blue-700">&#8599;</p>
                      </div>
                      <div className="flex gap-5">
                        <p className="">Name</p>
                        <p className="">
                          {orderdetails?._order_customers?.full_name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-gray-border-gray-lite border flex flex-grow  p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <Img src="/images/orderdetails/paperplane.svg" />
                        <p className="text-blue-700">Purpose</p>
                      </div>
                      <div className="flex gap-5">
                        {orderdetails?._purpose?.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-gray-border-gray-lite border flex flex-grow  p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <Img src="/images/orderdetails/percent.svg" />
                        <p className="text-blue-700">FCY Amount</p>
                      </div>
                      <div className="flex gap-5">
                        <p className="">
                          {orderdetails?.amount &&
                            formatCurrency(
                              parseInt(orderdetails?.amount.toFixed(0)),
                              orderdetails?.currency as string,
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-gray-border-gray-lite border flex flex-grow  p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <Img src="/images/orderdetails/piechart.svg" />
                        <p className="text-blue-700">Balance</p>
                      </div>
                      <div className="flex gap-5">
                        <p className="">
                          {orderdetails?.payable_amount &&
                            formatCurrency(
                              Number(orderdetails?.payable_amount.toFixed(0)),
                              "INR",
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-gray-border-gray-lite border w-full flex-col flex p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <Img src="/images/orderdetails/badgedollar.svg" />
                        <p className="">Total amount payable</p>
                      </div>
                      <div className="flex text-blue-700 gap-5">
                        <p className="">
                          {orderdetails?.payable_amount &&
                            formatCurrency(
                              Number(orderdetails?.payable_amount.toFixed(0)),
                              "INR",
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={() => setTab("amount-charges")}
                    className=" underline text-blue-700 text-right w-full"
                  >
                    View details
                  </Link>
                </div>
              </div> */}
            {/* </div> */}
            <div className="flex flex-row gap-4 w-full h-full">
              {/* Left Section */}
              <div className="w-[70%] flex flex-col gap-4">
                <div className="rounded-lg p-2 bg-[#FFEAC9] w-[400px]">
                  <h1 className="text-[#000]">
                    This order has been created from deal{" "}
                    <a
                      href={`/remit/dealdetails/${dealID}`}
                      className="text-blue-600 underline"
                    >
                      D - {dealID}
                    </a>
                  </h1>
                </div>
                <div className="rounded-lg p-5 bg-[#FAFAFA] w-full">
                  <div className="flex gap-4">
                    <h1 className="text-gray-400">Beneficiary:</h1>
                    <h1 className="text-black">
                      {" "}
                      {orderdetails?._order_customers?.full_name}
                    </h1>
                  </div>
                  <div className="flex gap-4">
                    <h1 className="text-gray-400">Purpose:</h1>
                    <h1 className="text-black">
                      {orderdetails?._purpose?.name}
                    </h1>
                  </div>
                  <div className="flex gap-4">
                    <h1 className="text-gray-400">Student:</h1>
                    <h1 className="text-black">
                      {studentName?.value || "N/A"}{" "}
                    </h1>
                  </div>
                </div>

                <div className="rounded-lg p-5 bg-[#FAFAFA] w-full flex flex-col gap-4">
                  <div className="flex justify-between items-center p-3 rounded-lg">
                    <h1 className="text-gray-400">FCY Amount</h1>
                    <div className="flex gap-5">
                      <p className="">
                        {orderdetails?.amount &&
                          formatCurrency(
                            parseInt(orderdetails?.amount.toFixed(0)),
                            orderdetails?.currency as string,
                          )}
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-between items-center p-3 bg-white rounded-lg">
                    <h1 className="text-gray-400">
                      Total Amount Payable{" "}
                      <span
                        className="text-blue-500 underline cursor-pointer"
                        onClick={() => setTab("amount-charges")}
                      >
                        View Breakup
                      </span>
                    </h1>
                    <div className="flex text-blue-700 gap-5">
                      <p className="">
                        {orderdetails?.payable_amount &&
                          formatCurrency(
                            parseInt(orderdetails?.payable_amount.toFixed(0)),
                            "INR",
                          )}
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-between items-center p-3 bg-[#FF000012] rounded-lg">
                    <h1 className="text-red-400">Balance</h1>
                    <div className="flex gap-5">
                      <p className="">{`INR ${balance}`}</p>
                    </div>
                  </div>

                  <div className="flex justify-between items-center p-3 bg-white rounded-lg">
                    <h1 className="text-gray-400">Customer Rate</h1>
                    <h1 className="text-black">INR {totalValue}</h1>
                  </div>
                </div>
                <div className="rounded-lg p-2 bg-[#FAFAFA] w-full flex flex-col gap-2">
                  <div className="flex justify-between items-center p-3 rounded-lg">
                    <h1 className="text-gray-400">My Margin :</h1>
                    <div className="flex items-center rounded-lg px-3">
                      <span className="text-black-500 text-[15px] p-1">
                        INR
                      </span>
                      <h1 className="text-black">
                        {level === "1"
                          ? margin.l1
                          : level === "2"
                            ? margin.l2
                            : margin.l3}
                      </h1>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-3 bg-white rounded-lg">
                    <h1 className="text-gray-400">Partner’s margin :</h1>
                    <h1 className="text-black">
                      INR {Number(level) + 1 === 2 ? margin.l2 : margin.l3}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-[30%] h-full relative space-y-4">
                <div className="h-full flex flex-col">
                  <label
                    htmlFor="notes"
                    className="text-sm font-medium text-gray-500 py-2"
                  >
                    Internal notes
                  </label>
                  {!isEditing ? (
                    <div className="flex items-center gap-5 w-full">
                      <div className="bg-[#FAFAFA] w-full flex items-center gap-2 py-2.5 px-2 rounded-lg text-left">
                        <div
                          className="bg-[#FAFAFA] w-full flex items-center gap-2 py-2.5 px-2 rounded-lg text-left relative"
                          style={{ border: "1px solid #D2D2D2" }}
                        >
                          <div className="flex flex-col gap-1 max-h-[300px] overflow-y-auto pr-6">
                            <p className="font-normal text-xs leading-[18px] text-[#999999]">
                              {orderdetails?.note_last_edited ? (
                                <>
                                  Last edited on{" "}
                                  {formatUnixTimestamp(
                                    orderdetails?.note_last_edited,
                                  )}
                                </>
                              ) : (
                                "Click on icon to add note"
                              )}
                            </p>
                            <p className="font-normal text-xs leading-[18px] text-[#999999] whitespace-pre-wrap">
                              {orderdetails?.note}
                            </p>
                          </div>

                          <button
                            onClick={openDetailNote}
                            className="absolute top-[10px] right-2"
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.33594 4.24609H2.5026C2.06058 4.24609 1.63665 4.42169 1.32409 4.73425C1.01153 5.04681 0.835938 5.47073 0.835938 5.91276V13.4128C0.835938 13.8548 1.01153 14.2787 1.32409 14.5913C1.63665 14.9038 2.06058 15.0794 2.5026 15.0794H10.0026C10.4446 15.0794 10.8686 14.9038 11.1811 14.5913C11.4937 14.2787 11.6693 13.8548 11.6693 13.4128V12.5794"
                                stroke="#165FE3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.8333 2.58472L13.3333 5.08472M14.4875 3.90556C14.8157 3.57735 15.0001 3.13221 15.0001 2.66806C15.0001 2.20391 14.8157 1.75876 14.4875 1.43056C14.1593 1.10235 13.7142 0.917969 13.25 0.917969C12.7858 0.917969 12.3407 1.10235 12.0125 1.43056L5 8.41806V10.9181H7.5L14.4875 3.90556Z"
                                stroke="#165FE3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full flex flex-col gap-2">
                      <textarea
                        value={tempNote || note}
                        placeholder="Enter note"
                        className="border border-gray-400 rounded-lg p-4 w-full h-[250px] bg-white"
                        onChange={(e) => setTempNote(e.target.value)}
                        rows={5}
                      />
                      <div className="flex justify-end gap-2">
                        <button
                          onClick={() =>
                            handleSave(parseInt(param?.id), tempNote)
                          }
                          className="text-white-A700 bg-blue-700 text-sm rounded-lg py-1.5 px-3 hover:bg-blue-700"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setIsEditing(false)}
                          className="text-gray-600 bg-gray-300 text-sm rounded-lg py-1.5 px-3 hover:bg-gray-400"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* Cancel Order Button */}
                {/* <button
                  style={{ border: "1px solid blue" }}
                  className="w-full px-4 py-3 text-blue-500 rounded-lg border-2"
                >
                  Cancel Order
                </button> */}
              </div>
            </div>
          </TabsContent>
          <TabsContent value="amount-charges">
            <section className="bg-white-A700 px-3 py-5 rounded-xl gap-4 flex flex-col">
              <p className="text-[#666666] leading-4 font-semibold">
                Order Summary
              </p>
              <div className="pt-5 pb-10 px-5 rounded-xl shadow-order-summary gap-10">
                <div className="divide-y divide-neutral-400_1 gap-5 flex flex-col">
                  <div className="flex flex-col gap-4 w-full">
                    <div className="flex justify-between w-full">
                      <span className="text-sm indent-3.5 text-[#666666]">
                        FX Amount
                      </span>
                      <div>
                        <span className="text-sm font-medium">
                          {formatCurrency(
                            orderdetails?.amount as number,
                            orderdetails?.currency as string,
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-between w-full">
                      <span className="text-sm indent-3.5 text-[#666666]">
                        Total Charges
                      </span>
                      <div className="text-sm font-medium">
                        <span>
                          {formatCurrency(
                            parseFloat(
                              Number(
                                Number(orderdetails?.gst) +
                                  Number(orderdetails?.tcs) +
                                  Number(orderdetails?.tt_charges_inclgst) +
                                  Number(
                                    orderdetails?.foreign_bank_rates_inclgst,
                                  ),
                              )?.toFixed(4),
                            ),
                            "INR",
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="w-full border-[.3px]" />
                    <div className="flex flex-col w-full gap-4">
                      <div className="flex justify-between w-full">
                        <span className="text-sm indent-3.5 text-[#666666]">
                          GST on FX conversion
                        </span>
                        <span className="text-sm font-medium">
                          {formatCurrency(
                            Number(orderdetails?.gst?.toFixed(2) as string),
                            "INR",
                          )}
                        </span>
                      </div>
                      <div className="flex justify-between w-full mb-2">
                        <span className="text-sm indent-3.5 text-[#666666]">
                          TCS{" "}
                          <span className="text-xs text-gray-500">
                            (incl. GST)
                          </span>
                        </span>
                        {/* <span className="text-sm font-medium">
                          {formatCurrency(
                            Number(orderdetails?.tcs?.toFixed(2) as string),
                            "INR",
                          )}
                        </span> */}
                        <div className="flex items-center gap-2">
                          <span className="text-sm font-medium">
                            {orderdetails?.tcs &&
                              `INR ${parseInt(orderdetails?.tcs.toFixed(2))}`}
                          </span>

                          <button
                            onClick={() => setIsTCSEditing(true)}
                            className="text-gray-500 hover:text-black"
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.33594 4.24609H2.5026C2.06058 4.24609 1.63665 4.42169 1.32409 4.73425C1.01153 5.04681 0.835938 5.47073 0.835938 5.91276V13.4128C0.835938 13.8548 1.01153 14.2787 1.32409 14.5913C1.63665 14.9038 2.06058 15.0794 2.5026 15.0794H10.0026C10.4446 15.0794 10.8686 14.9038 11.1811 14.5913C11.4937 14.2787 11.6693 13.8548 11.6693 13.4128V12.5794"
                                stroke="#165FE3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.8333 2.58472L13.3333 5.08472M14.4875 3.90556C14.8157 3.57735 15.0001 3.13221 15.0001 2.66806C15.0001 2.20391 14.8157 1.75876 14.4875 1.43056C14.1593 1.10235 13.7142 0.917969 13.25 0.917969C12.7858 0.917969 12.3407 1.10235 12.0125 1.43056L5 8.41806V10.9181H7.5L14.4875 3.90556Z"
                                stroke="#165FE3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="flex justify-between w-full">
                        <span className="text-sm indent-3.5 text-[#666666]">
                          TT charges{" "}
                          <span className="text-xs text-gray-500">
                            (incl. GST)
                          </span>
                        </span>
                        <span className="text-sm font-medium">
                          {formatCurrency(
                            Number(
                              orderdetails?.tt_charges_inclgst?.toFixed(
                                2,
                              ) as string,
                            ),
                            "INR",
                          )}
                        </span>
                      </div>
                      <div className="flex justify-between w-full">
                        <span className="text-sm indent-3.5 text-[#98BDFF]">
                          Discount <span className="text-xs">%</span>
                        </span>
                      </div>
                      <div className="flex justify-between w-full mb-2">
                        <span className="text-sm indent-3.5 text-[#666666]">
                          Foreign Bank charges{" "}
                          <span className="text-xs text-gray-500">
                            (incl. GST)
                          </span>
                        </span>
                        <span className="text-sm font-medium">
                          {orderdetails?.foreign_bank_rates_inclgst?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between pt-2.5 text-blue-700 w-full">
                    <span className="text-md indent-3.5 font-semibold">
                      Total amount payable
                    </span>
                    <div className="flex gap-1 items-center ">
                      <span className="text-md font-medium">
                        {formatCurrency(
                          parseInt(
                            orderdetails?.payable_amount.toFixed(2) as string,
                          ),
                          "INR",
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <Accordion
                  className="w-full sm:w-full"
                  type="single"
                  collapsible
                >
                  <AccordionItem value="address" className="sm:w-full">
                    <AccordionTrigger className="disabled:opacity-40 px-4 py-3 rounded-lg bg-gray-300">
                      <div className="flex justify-between items-center w-full">
                        <div className="flex items-center gap-2">
                          <span className="text-base font-medium">
                            View Internal Transaction Breakup
                          </span>
                        </div>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="w-full pl-6 sm:w-full">
                      {/* <div className="flex flex-col gap-6 py-4">
                        {[
                          {
                            label: "Customer rate",
                            value: `INR ${totalValue}`,
                          },
                          { label: "Partner rate", value: `INR ${Number(orderdetails?.service_partner_rates)}` },
                          {
                            label: "Settlement rate",
                            value: `INR ${Number(orderdetails?.service_partner_rates)}`,
                          },
                        ].map((item, index) => (
                          <div
                            key={index}
                            className="relative flex items-start gap-4"
                          >
                            <div className="relative flex flex-col items-center">
                              <img
                                className="w-5 h-5"
                                src="/images/overview/ellipse.svg"
                                alt="Step"
                              />
                              {index < 2 && (
                                <div className="w-[2px] h-10 bg-blue-700" />
                              )}
                            </div>
                            <div className="flex flex-col gap-2">
                              <span className="text-sm font-medium leading-[17px]">
                                {item.label}: {item.value}
                              </span>
                              {index === 0 && (
                                <div className="p-3 mt-2 bg-gray-100 rounded-lg">
                                  <span className="text-sm text-[#666666] font-medium leading-[17px]">
                                    Partner margin: INR {level === "1" ? margin.l1 : level === "2" ? margin.l2 : margin.l3}
                                  </span>
                                </div>
                              )}
                              {index === 1 && (
                                <div className="p-3 mt-2 bg-gray-100 rounded-lg">
                                  <span className="text-sm text-[#666666] font-medium leading-[17px]">
                                    MoneeFlo margin: INR{" "}
                                    {level === "1"
                                      ? margin.l1
                                      : level === "2"
                                        ? margin.l2
                                        : margin.l3}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div> */}
                      <StepIndicator
                        orderdetails={orderdetails}
                        margin={margin}
                        level={level}
                      />
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </div>
              <AlertDialog open={isTCSEditing} onOpenChange={setIsTCSEditing}>
                <AlertDialogPortal>
                  <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                  <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-md animate-contentShow">
                    <form className="max-w-md">
                      <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                          Edit TCS charges
                        </AlertDialogTitle>
                        <div className="flex flex-col gap-4">
                          <div className="flex items-center border border-black/30 rounded-lg px-3 py-2">
                            <span className="bg-gray-100 border border-gray-300 rounded-lg text-gray-500 text-[15px] p-3">
                              INR
                            </span>
                            <input
                              className="flex-grow text-[15px] pl-4 border-none focus:outline-none"
                              type="number"
                              value={tcsValue}
                              onChange={(e) => setTcsValue(e.target.value)}
                              placeholder="Enter new TCS charges"
                            />
                          </div>

                          <div className="bg-gray-100 p-2 rounded-md">
                            <label
                              htmlFor="currenttcs"
                              className="text-sm font-medium"
                            >
                              Current TCS:{" "}
                              <span className="font-semibold">
                                {orderdetails?.tcs || 0}
                              </span>
                            </label>
                          </div>

                          <div className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              id="tcsConfirmation"
                              className="w-4 h-4 cursor-pointer"
                              required
                              checked={isChecked}
                              onChange={(e) => setIsChecked(e.target.checked)}
                            />
                            <label
                              htmlFor="tcsConfirmation"
                              className="text-sm"
                            >
                              I hereby declare the TCS charges are updated as
                              confirmed by AD22.
                            </label>
                          </div>
                        </div>
                      </AlertDialogHeader>
                      <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                          className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                          asChild
                        >
                          <button onClick={handleTCSCancel}>Close</button>
                        </AlertDialogCancel>
                        <AlertDialogAction
                          type="submit"
                          onClick={() =>
                            handleTCSSave(
                              parseInt(param?.id),
                              "",
                              parseInt(tcsValue),
                            )
                          }
                          className={`rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700 ${
                            !isChecked ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                          disabled={!isChecked}
                        >
                          Save
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </form>
                  </AlertDialogContent>
                </AlertDialogPortal>
              </AlertDialog>
            </section>
          </TabsContent>
          <TabsContent value="documents">
            {/* <section className="bg-white-A700 px-3 py-5 rounded-xl gap-5 flex flex-col">
              <p className="text-[#999999] leading-4 font-medium text-base">
                Documentation
              </p>
              <div className="w-full">
                <div className="grid grid-cols-2 gap-x-10 gap-y-4">
                  {orderdetails?.supporting_documents?.map(
                    (doc: { filename: string }, key: number) => (
                      <div
                        key={key}
                        className="flex flex-nowrap flex-auto gap-4 w-auto items-center"
                      >
                        <span className="font-medium">
                          {doc?.filename.split(".")[0]}:
                        </span>
                        <div className="flex-1 flex gap-5 items-center justify-end">
                          <span className="text-gray-600">{doc?.filename}</span>
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.83594 4.32812H3.0026C2.56058 4.32813 2.13665 4.50372 1.82409 4.81628C1.51153 5.12884 1.33594 5.55276 1.33594 5.99479V13.4948C1.33594 13.9368 1.51153 14.3607 1.82409 14.6733C2.13665 14.9859 2.56058 15.1615 3.0026 15.1615H10.5026C10.9446 15.1615 11.3686 14.9859 11.6811 14.6733C11.9937 14.3607 12.1693 13.9368 12.1693 13.4948V12.6615"
                              stroke="#165FE3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3372 2.66676L13.8372 5.16676M14.9914 3.98759C15.3196 3.65938 15.504 3.21424 15.504 2.75009C15.504 2.28594 15.3196 1.84079 14.9914 1.51259C14.6632 1.18438 14.2181 1 13.7539 1C13.2898 1 12.8446 1.18438 12.5164 1.51259L5.50391 8.50009V11.0001H8.00391L14.9914 3.98759Z"
                              stroke="#165FE3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            </section> */}
            <section className="bg-white-A700 px-3 py-5 rounded-xl gap-5 flex flex-col">
              <p className="text-[#999999] leading-4 font-medium text-base">
                Documentation
              </p>
              <div
                className={`${orderdetails?.doc_status === "Review" ? "bg-red-50" : "bg-green-50"} p-4 rounded-lg mb-8 flex items-center justify-between`}
              >
                <div className="flex items-center gap-4">
                  {orderdetails?.doc_status === "Review" ? (
                    <AlertCircle className="h-5 w-5 text-red-500" />
                  ) : (
                    <CheckCircle className="h-5 w-5 text-green-500" />
                  )}
                  <div>
                    <span className="font-medium">Document status: </span>
                    <span
                      className={`${orderdetails?.doc_status === "Review" ? "text-red-500" : "text-green-500"}`}
                    >
                      {orderdetails?.doc_status === "Review"
                        ? "Unverified"
                        : "Verified"}
                    </span>
                    <AlertDialog>
                      <AlertDialogTrigger>
                        <Button
                          variant="link"
                          disabled={orderdetails?.doc_status !== "Review"}
                          className="text-blue-600 pl-2"
                        >
                          Verify Documents
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogPortal>
                        <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                        <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                          <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10">
                            <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-gray-900">
                              Verify documents
                            </AlertDialogTitle>
                          </AlertDialogHeader>
                          <form className="px-10 pb-4">
                            <label
                              htmlFor="verify"
                              className="flex gap-2 text-sm leading-5 font-normal text-[#666666] items-center"
                            >
                              <input
                                checked={verifyDocs}
                                onChange={(e) => {
                                  setVerifyDocs(e.target.checked);
                                }}
                                type="checkbox"
                                name="verify"
                                id="verify"
                              />
                              I here by declare on behalf of AD2 that the
                              documents submitted by are correct and valid.
                            </label>
                          </form>
                          <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-50">
                            <AlertDialogCancel className="rounded-lg m-0 border-blue-700 border py-[11px] px-4 text-blue-700">
                              Close
                            </AlertDialogCancel>
                            <AlertDialogAction
                              type="submit"
                              onClick={() => {
                                handleDocReview(verifyDocs);
                              }}
                              className="rounded-lg border-blue-700 py-[11px] px-4 text-white-A700 bg-blue-700 border"
                            >
                              Verify
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogPortal>
                    </AlertDialog>
                  </div>
                </div>
                <div className="flex gap-4">
                  <Button
                    variant="default"
                    className="rounded-lg py-[11px] flex gap-2 px-4 bg-blue-700 text-white-A700 font-semibold text-sm"
                  >
                    Request Docs.
                  </Button>
                  <FileUploadDialog onSubmit={() => {}}>
                    <Button
                      variant="outline"
                      className="rounded-lg py-[11px] flex gap-2 px-4 bg-blue-700 text-white-A700 font-semibold text-sm"
                    >
                      <UploadCloud className="h-4 w-4" />
                      <button className="">Upload Document</button>
                    </Button>
                  </FileUploadDialog>
                </div>
              </div>

              {/* Document Sections */}
              <div className="space-y-8">
                {/* KYC Documents */}
                {/* <section>
                  <h3 className="text-lg text-gray-500 mb-4">KYC Documents</h3>
                  <DocumentList documents={[{ name: "PAN card" }, { name: "Aadhaar card" }]} />
                </section> */}

                {/* Order Documentation */}
                <section>
                  <h3 className="text-lg text-gray-500 mb-4">
                    Order Documents
                  </h3>
                  <DocumentList
                    documents={orderdetails?.supporting_documents.map((doc) => {
                      return {
                        name: doc?.filename as string,
                        url: doc?.file?.url as string,
                      };
                    })}
                  />
                </section>

                {/* Additional Documentation */}
                {/* <section>
                  <h3 className="text-lg text-gray-500 mb-4">Additional Documentation</h3>
                  <DocumentList documents={[{ name: "Voter ID" }, { name: "Purchase paper" }, { name: "Driving license" }]} />
                </section> */}

                {/* LRS Declarations */}
                <section>
                  <h3 className="text-lg text-gray-500 mb-4">
                    LRS Declarations
                  </h3>
                  <div className="bg-[#2F3336] text-white-A700 rounded-t-lg">
                    <div className="grid grid-cols-3 p-4">
                      <div>Ref number</div>
                      <div>Amount</div>
                      <div>Supporting Document</div>
                    </div>
                  </div>
                  <div className="border rounded-b-lg">
                    <div className="grid grid-cols-3 p-4">
                      <div>N/A</div>
                      <div>N/A</div>
                      <div className="flex gap-4">
                        <Button
                          disabled
                          variant="ghost"
                          size="sm"
                          className="text-blue-600"
                        >
                          <Eye className="h-4 w-4" />
                        </Button>
                        <Button
                          disabled
                          variant="ghost"
                          size="sm"
                          className="text-blue-600"
                        >
                          <UploadCloud className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="w-full hidden">
                <div className="grid grid-cols-2 gap-x-10 gap-y-4">
                  {orderdetails?.supporting_documents?.map(
                    (doc: { filename: string }, key: number) => (
                      <div
                        key={key}
                        className="flex flex-nowrap flex-auto gap-4 w-auto items-center"
                      >
                        <span className="font-medium">
                          {doc?.filename.split(".")[0]}:
                        </span>
                        <div className="flex-1 flex gap-5 items-center justify-end">
                          <span className="text-gray-600">{doc?.filename}</span>
                          {/* <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.83594 4.32812H3.0026C2.56058 4.32813 2.13665 4.50372 1.82409 4.81628C1.51153 5.12884 1.33594 5.55276 1.33594 5.99479V13.4948C1.33594 13.9368 1.51153 14.3607 1.82409 14.6733C2.13665 14.9859 2.56058 15.1615 3.0026 15.1615H10.5026C10.9446 15.1615 11.3686 14.9859 11.6811 14.6733C11.9937 14.3607 12.1693 13.9368 12.1693 13.4948V12.6615"
                              stroke="#165FE3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3372 2.66676L13.8372 5.16676M14.9914 3.98759C15.3196 3.65938 15.504 3.21424 15.504 2.75009C15.504 2.28594 15.3196 1.84079 14.9914 1.51259C14.6632 1.18438 14.2181 1 13.7539 1C13.2898 1 12.8446 1.18438 12.5164 1.51259L5.50391 8.50009V11.0001H8.00391L14.9914 3.98759Z"
                              stroke="#165FE3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg> */}
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            </section>
          </TabsContent>
          <TabsContent value="payments">
            <section className="bg-white-A700 px-3 py-5 rounded-xl gap-5 flex flex-col">
              <div className="flex justify-between">
                <div className="flex gap-5 items-center">
                  <p className="leading-4 font-semibold">Payments</p>
                  <p
                    style={{
                      backgroundColor: order_status_options.find(
                        (status) => orderdetails?.status === status.value,
                      )?.bgColor,
                      color: order_status_options.find(
                        (status) => orderdetails?.status === status.value,
                      )?.textColor,
                    }}
                    className="py-[4px] text-sm leading-4 capitalize font-medium  px-2 text-center rounded-md"
                  >
                    {orderdetails?.payment_status}
                  </p>
                </div>
                {orderdetails?.status !== "cancelled" && (
                  <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
                    <AlertDialogTrigger>
                      <button
                        disabled={orderdetails?.payment_status === "recieved"}
                        className="rounded-lg py-[11px] disabled:opacity-40 px-4 border bg-blue-700 text-white-A700 font-semibold text-sm"
                      >
                        Capture Payment
                      </button>
                    </AlertDialogTrigger>
                    <AlertDialogPortal>
                      <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                      <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-md animate-contentShow">
                        <form className="max-w-md">
                          <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                            <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                              Add Payment Details
                            </AlertDialogTitle>
                            <div className="flex flex-col gap-4">
                              <div className="flex gap-2 flex-shrink flex-col w-full">
                                <label
                                  htmlFor="acknowledgement_no"
                                  className=" text-sm font-medium leading-4"
                                >
                                  Acknowledgement Number
                                </label>
                                <input
                                  className="border focus:border-blue-700 p-3 rounded-lg"
                                  id="acknowledgement_no"
                                  type="number"
                                  {...register("acknowledgement_no", {
                                    required:
                                      "Acknowledgement Number is required",
                                  })}
                                />
                                {errors.acknowledgement_no && (
                                  <p className="text-red-500 text-xs mt-1">
                                    {
                                      errors.acknowledgement_no
                                        .message as string
                                    }
                                  </p>
                                )}
                              </div>
                              <div className="flex gap-2 flex-col w-full">
                                <label
                                  htmlFor="amount"
                                  className=" text-sm font-medium leading-4"
                                >
                                  Amount
                                </label>
                                <input
                                  id="amount"
                                  type="number"
                                  className="border focus:border-blue-700 p-3 rounded-lg"
                                  {...register("amount", {
                                    required: "Amount is required",
                                  })}
                                />
                                {errors.amount && (
                                  <p className="text-red-500 text-xs mt-1">
                                    {errors.amount.message as string}
                                  </p>
                                )}
                              </div>
                              <div className="flex gap-2 flex-1 flex-grow flex-col w-full">
                                <h3 className=" text-sm font-medium leading-4">
                                  Supporting document
                                </h3>
                                <div
                                  className={`py-1.5 px-3.5 justify-between rounded-md flex bg-[#F2F7FF]`}
                                >
                                  <div className="flex gap-3 items-center overflow-hidden">
                                    <Img src="/images/remit/file.svg" alt="" />
                                    <p className=" text-ellipsis truncate">
                                      {selectedFile?.name || "No file selected"}
                                    </p>
                                  </div>
                                  <label
                                    htmlFor="supporting-document"
                                    className=" cursor-pointer text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                                  >
                                    {selectedFile ? "Re-upload" : "Upload"}
                                  </label>
                                  <input
                                    {...register("supporting-document", {
                                      required:
                                        "Supporting document is required",
                                      onChange: handleFileChange,
                                    })}
                                    type="file"
                                    id="supporting-document"
                                    className="hidden"
                                    accept="image/*"
                                  />
                                </div>
                                {errors["supporting-document"] && (
                                  <p className="text-red-500 text-xs mt-1">
                                    {
                                      errors["supporting-document"]
                                        .message as string
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                          </AlertDialogHeader>
                          <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                            <AlertDialogCancel
                              className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                              asChild
                            >
                              <button
                                onClick={() => {
                                  reset();
                                  setSelectedFile(null);
                                }}
                              >
                                Close
                              </button>
                            </AlertDialogCancel>
                            <AlertDialogAction
                              type="submit"
                              onClick={handleSubmit(onSaveClick)}
                              className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                            >
                              Capture
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </form>
                      </AlertDialogContent>
                    </AlertDialogPortal>
                  </AlertDialog>
                )}
              </div>
              <DataTable
                isToolbarVisible={false}
                isPaginationVisible={false}
                columnsBackground="#333333"
                data={paymentData}
                columns={columns}
                highlightRow={!!rowSelected}
                onRowClick={handleAction}
              />
            </section>
          </TabsContent>
          <TabsContent value="other-details">
            <div className="grid grid-cols-2 gap-8 w-full">
              <div className="w-full flex flex-col flex-grow gap-2">
                <h5 className="text-[#999999] leading-4 font-medium text-base">
                  Remitter Details
                </h5>
                {/* <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Name:{" "}
                        <span className={valueStyle}>
                          {orderdetails?._order_customers?.full_name}
                        </span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        PAN Card:{" "}
                        <span className={valueStyle}>
                          {orderdetails?._order_customers?.pan_no}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Email:
                        <span className={valueStyle}>
                          {" "}
                          {orderdetails?._order_customers?.email}
                        </span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Aadhaar Card:
                        <span className={valueStyle}>
                          {orderdetails?._order_customers?.aadhaar_no
                            ? ` **** **** ${orderdetails?._order_customers?.aadhaar_no}`
                            : "N/A"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Mobile:
                        <span className={valueStyle}>
                          {orderdetails?._order_customers?.phone
                            ? orderdetails?._order_customers?.phone
                            : " N/A"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="rounded-lg bg-[#FBFBFB] flex flex-col justify-between w-full p-2">
                  <div className="flex flex-row gap-2 items-center">
                    <h1>{orderdetails?._order_customers?.full_name}</h1>
                    <span className="bg-gray-300 text-black-200 rounded-lg p-2">
                      N/A
                    </span>
                  </div>
                  <div className="flex text-gray-400 items-center gap-2">
                    <h1>Email :</h1>
                    <span className={valueStyle} style={{ color: "gray" }}>
                      {orderdetails?._order_customers?.email}
                    </span>
                  </div>
                  <div className="flex text-gray-400 items-center gap-2">
                    <h1>Mobile :</h1>
                    <span className={valueStyle} style={{ color: "gray" }}>
                      {orderdetails?._order_customers?.phone
                        ? orderdetails?._order_customers?.phone
                        : " N/A"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col flex-grow gap-2">
                <h5 className="text-[#999999] leading-4 font-medium text-base">
                  Student details
                </h5>
                <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
                  {/* <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Name:{" "}
                        <span className={valueStyle}>
                          {" "}
                          {orderdetails?._order_service_partner.display_name}
                        </span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Service ID:{" "}
                        <span className={valueStyle}>
                          {" "}
                          {orderdetails?._order_service_partner.id}
                        </span>
                      </p>
                    </div>
                  </div> */}
                  {/* <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        State:
                        <span className={valueStyle}>
                          {" "}
                          {orderdetails?._order_service_partner
                            .legal_addr_state || "N/A"}
                        </span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        City:
                        <span className={valueStyle}>
                          {" "}
                          {orderdetails?._order_service_partner
                            .legal_addr_city || "N/A"}
                        </span>
                      </p>
                    </div>
                  </div> */}
                  {/* <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Email:
                        <span className={valueStyle}>
                          {" "}
                          {orderdetails?._order_service_partner.email}
                        </span>
                      </p>
                    </div>
                  </div> */}
                  <div className="rounded-lg bg-[#FBFBFB] flex flex-col justify-between w-full">
                    <div className="flex text-gray-400 item-center gap-2">
                      <h1>Name :</h1>
                      <span className={valueStyle}>
                        {studentName?.value || "N/A"}
                      </span>
                    </div>
                    <div className="flex text-gray-400 item-center gap-2">
                      <h1>Passport :</h1>
                      <span className={valueStyle} style={{ color: "gray" }}>
                        {orderdetails?._order_service_partner.passport_no ||
                          "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col flex-grow gap-5 my-8">
              <h5 className="text-[#999999] leading-4 font-medium text-base">
                Beneficiary details
              </h5>
              <div className="flex gap-5 p-5 flex-col rounded-xl bg-[#FBFBFB] border border-white-A700 ">
                <div className="rounded-lg  flex flex-col justify-between w-full gap-3">
                  <div className="flex flex-row gap-2 items-center">
                    <span
                      className="bg-[#EFF4FF] text-black-200 rounded-lg p-2"
                      style={{ fontWeight: "400" }}
                    >
                      Institution
                    </span>
                    <h1 style={{ fontWeight: "700" }}>
                      {orderdetails?._beneficiary?.institution || "N/A"}
                    </h1>
                  </div>
                  <div className="flex text-gray-400 item-center gap-4">
                    <h1>Bank name:</h1>
                    <span className={valueStyle}>
                      {orderdetails?._beneficiary?.bank_name}
                    </span>
                  </div>
                  <div className="flex text-gray-400 item-center gap-4">
                    <h1>Account number / IBAN :</h1>
                    {(orderdetails?._beneficiary?.account_number ||
                      orderdetails?._beneficiary?.iban_no) && (
                      <span className={valueStyle}>
                        {orderdetails?._beneficiary?.account_number ||
                          orderdetails?._beneficiary?.iban_no}
                      </span>
                    )}
                  </div>
                  <div className="flex text-gray-400 item-center gap-4">
                    <h1>SWIFT Code:</h1>
                    <span className={valueStyle}>
                      {orderdetails?._beneficiary?.swift_code || "N/A"}
                    </span>
                  </div>
                  <div className="flex text-gray-400 item-center gap-4">
                    <h1>Reference number :</h1>
                    <span className={valueStyle}>
                      {orderdetails?._beneficiary?.reference_no || "N/A"}
                    </span>
                  </div>
                  <div className="flex text-gray-400 item-center gap-4">
                    <h1>Correspondent bank name :</h1>
                    <span className={valueStyle}>
                      {orderdetails?._beneficiary?.correspondant_bank_name ||
                        "N/A"}
                    </span>
                  </div>
                  <div className="flex text-gray-400 item-center gap-4">
                    <h1>Correspondent bank number :</h1>
                    <span className={valueStyle}>
                      {orderdetails?._beneficiary?.correspondant_bank_no ||
                        "N/A"}
                    </span>
                  </div>
                  <div className="flex text-gray-400 item-center gap-4">
                    <h1>Bank Address :</h1>
                    <span className={valueStyle}>
                      {orderdetails?._beneficiary?.bank_addr || "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* partner details */}
            {channelDetails?.id === 12 && (
              <div className="w-full flex flex-col flex-grow gap-5 my-8">
                <h5 className="text-[#999999] leading-4 font-medium text-base">
                  Partner Details
                </h5>
                <div className="flex gap-5 p-5 bg-[#FBFBFB] flex-col rounded-xl border border-white-A700">
                  <div className="rounded-lg flex flex-col justify-between w-full gap-3">
                    <div className="flex flex-row gap-2 items-center">
                      <span
                        className="bg-[#EFF4FF] text-black-200 rounded-lg p-2"
                        style={{ fontWeight: "400" }}
                      >
                        Travel agency
                      </span>
                      <h1 style={{ fontWeight: "700" }}>
                        {orderdetails?.partner_detail?.name || "N/A"}
                      </h1>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Partner Trade :{" "}
                        <span className={valueStyle}>
                          {orderdetails?.partner_detail?.type || "N/A"}
                        </span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Branch Location:{" "}
                        <span className={valueStyle}>
                          {orderdetails?.partner_detail?.city || "N/A"},{" "}
                          {orderdetails?.partner_detail?.state || "N/A"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Service provider details */}
            <div className="w-full flex flex-col flex-grow gap-5 my-8">
              <h5 className="text-[#999999] leading-4 font-medium text-base">
                Service provider details
              </h5>
              <div className="flex gap-5 p-5 bg-[#FBFBFB] flex-col rounded-xl border border-white-A700">
                <div className="rounded-lg flex flex-col justify-between w-full gap-3">
                  <div className="flex flex-row gap-2 items-center">
                    <h1 style={{ fontWeight: "700" }}>
                      {orderdetails?._order_service_partner?.display_name}
                    </h1>
                  </div>
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Email :{" "}
                      <span className={valueStyle}>
                        {orderdetails?._order_service_partner?.email}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Service ID:{" "}
                      <span className={valueStyle}>
                        {orderdetails?._order_service_partner?.id}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Location:{" "}
                      <span className={valueStyle}>
                        {orderdetails?._order_service_partner
                          ?.legal_addr_city &&
                        orderdetails?._order_service_partner?.legal_addr_state
                          ? `${orderdetails?._order_service_partner?.legal_addr_city}, ${orderdetails?._order_service_partner?.legal_addr_state}`
                          : orderdetails?._order_service_partner
                              ?.legal_addr_city ||
                            orderdetails?._order_service_partner
                              ?.legal_addr_state ||
                            "N/A"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="w-full flex flex-col flex-grow gap-2"> */}
            {/* <h5>Partner Details</h5> */}
            {/* <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]"> */}
            {/* <div className="rounded-lg flex justify-between w-full">
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Name: <span className={valueStyle}></span>
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Parner ID: <span className={valueStyle}></span>
                    </p>
                  </div>
                </div> */}

            <div className="rounded-lg p-2 bg-[#FFEAC9] w-[450px]">
              <h1 className="text-[#000]">
                This order has been created from Lead ID{" "}
                <a
                  href={`/remit/leads?dealid=${orderdetails?.opportunity_id}`}
                  className="text-blue-600 underline"
                >
                  D - {orderdetails?.opportunity_id}
                </a>
              </h1>
            </div>
            {/* <div className="rounded-lg flex justify-between w-full">
                  <div className="flex gap-2">
                    <p className={labelStyle}></p>
                  </div>
                  <div className="flex gap-2">
                    <p className={labelStyle}></p>
                  </div>
                </div> */}
            {/* </div> */}
            {/* </div> */}
          </TabsContent>
        </Tabs>
      </div>
      <div className="flex float-right absolute -top-3 -right-4 gap-2">
        <AlertDialog open={isOpen}>
          <AlertDialogTrigger onClick={() => setIsopen(false)}>
            <div className=" cursor-pointer ">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1689_36824)">
                  <path
                    d="M3.83594 4.82812H3.0026C2.56058 4.82813 2.13665 5.00372 1.82409 5.31628C1.51153 5.62884 1.33594 6.05276 1.33594 6.49479V13.9948C1.33594 14.4368 1.51153 14.8607 1.82409 15.1733C2.13665 15.4859 2.56058 15.6615 3.0026 15.6615H10.5026C10.9446 15.6615 11.3686 15.4859 11.6811 15.1733C11.9937 14.8607 12.1693 14.4368 12.1693 13.9948V13.1615"
                    stroke="#165FE3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.3333 3.16676L13.8333 5.66676M14.9875 4.48759C15.3157 4.15938 15.5001 3.71424 15.5001 3.25009C15.5001 2.78594 15.3157 2.34079 14.9875 2.01259C14.6593 1.68438 14.2142 1.5 13.75 1.5C13.2858 1.5 12.8407 1.68438 12.5125 2.01259L5.5 9.00009V11.5001H8L14.9875 4.48759Z"
                    stroke="#165FE3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1689_36824">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </AlertDialogTrigger>
          <AlertDialogPortal>
            <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
            <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl w-full animate-contentShow sm:max-w-[90%]">
              <AlertDialogHeader className="flex text-left border-b  rounded-t py-5 px-10 ">
                <AlertDialogTitle className="font-bold capitalize p-0 text-lg text-black-label">
                  Review Documents
                </AlertDialogTitle>
              </AlertDialogHeader>
              <div className="h-[500px] overflow-auto w-full">
                <PaymentReviewModal
                  setIsopen={setIsopen}
                  paymentData={paymentData}
                />
              </div>
            </AlertDialogContent>
          </AlertDialogPortal>
        </AlertDialog>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

function DocumentList({
  documents,
}: {
  documents: { name: string; url: string }[];
}) {
  return (
    <div className="space-y-4">
      {documents.map((doc, index) => (
        <div
          key={index.toFixed(2)}
          className="flex items-center justify-between border-b pb-4"
        >
          <span>{doc.name}</span>
          <div className="flex gap-4">
            <Button
              onClick={() => {
                if (doc.url) window.open(doc.url, "_blank");
                else toast.error("Document not available");
              }}
              variant="ghost"
              className="text-blue-600"
            >
              <Eye className="h-4 w-4 mr-2" />
              View
            </Button>
            <Button variant="ghost" className="text-blue-600" disabled>
              Re-Upload
              <RefreshCw className="h-4 w-4 mr-2" />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default NewOrderDetailsPage;
