import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Option } from "pages/Opportunities/types";
import { useEffect } from "react";
import {
  setProduct,
  setProductPurpose,
} from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";

interface ContactDetailsProps {
  errors: Record<string, string>;
}

const productOptions: Option[] = [{ value: "remittance", label: "Remittance" }];

const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "19", label: "Family Maintenance" },
  { value: "20", label: "Gift" },
];

export default function LevelTwoContactDetails({
  errors,
}: ContactDetailsProps) {
  const { product_purpose, product, pipelineType } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const dispatch = useAppDispatch();

  const productPurposeOptions = {
    remittance: remitProductPurposeOptions,
  };

  const defaultProductPurpose: {
    [key: string]: string;
  } = {
    remittance: "17",
  };

  useEffect(() => {
    if (product) {
      dispatch(setProductPurpose(defaultProductPurpose[product]));
    }
  }, [product]);

  switch (pipelineType) {
    case "retail":
      return (
        <form className="max-w-md">
          <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-16 after:border-blue-700" : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <Tabs
                className="flex rounded-lg gap-2"
                value={product}
                onValueChange={async (tab) => {
                  dispatch(setProduct(tab));
                  dispatch(setProductPurpose(""));
                }}
              >
                <TabsList className=" sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap">
                  {productOptions.map(
                    (tab: { label: string; value: string }) => (
                      <TabsTrigger
                        key={tab.value}
                        value={tab.value}
                        className={` py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${tab.value === product ? " bg-navbar-menu-bg text-blue-700" : " text-black-label bg-[#F1F1F1]"}`}
                      >
                        {tab.label}
                      </TabsTrigger>
                    ),
                  )}
                </TabsList>
              </Tabs>
            </div>
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${false ? "after:h-44 after:border-blue-700" : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label
                htmlFor="customer_contact_phone"
                className={`text-sm ${!product ? "text-[#C4C4C4]" : "text-[#6B7280]"} `}
              >
                Select one to proceed
              </label>
              {product && (
                <RadioGroup
                  className="flex w-full flex-col gap-4"
                  value={product_purpose}
                  defaultValue="myself"
                  onValueChange={(e) => {
                    dispatch(setProductPurpose(e));
                  }}
                >
                  {productPurposeOptions[
                    product as keyof typeof productPurposeOptions
                  ].map((tab: { label: string; value: string }) => (
                    <label
                      htmlFor={tab.value}
                      className={`${product_purpose === tab.value ? " focus-within:border-blue-700 border-black-label" : ""} flex w-auto items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value={tab.value}
                        id={tab.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="w-full">{tab.label}</p>
                    </label>
                  ))}
                </RadioGroup>
              )}
            </div>
          </div>
        </form>
      );
    default:
      return null;
  }
}
