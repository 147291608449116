import React from "react";
import { CircleCheck, Search, CircleX, Check } from "lucide-react";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useEffect, useRef, useState } from "react";
import { AxiosRequestConfig } from "axios";
import { toast } from "sonner";
import { Spinner } from "components";
import { useNavigate } from "react-router-dom";
import { patchLinkPartner } from "service/apis/Opportunities";
import axios from "axios";
import { CreatePartnerLeadType } from "pages/CreatePartnerLead/schema";
import { FormProvider, useFormContext } from "react-hook-form";

interface LinkPartnerProps {
  readonly children: React.ReactNode;
  readonly handleSubmit?: (partnerId: string) => void;
  readonly otherProps?: any;
  readonly onDone?: () => void;
  readonly showLinkPartnerButton?: boolean;
  readonly contact_name: string;
  readonly onClick?: () => Promise<void>;
}

export interface Partner {
  id: string;
  name: string;
  email: string;
  role: string;
}

const productOptions = [
  { label: "Owner/Director", value: "owner" },
  { label: "Employee", value: "employee" },
  { label: "Other", value: "other" },
];

export default function LinkPartner({
  children,
  handleSubmit,
  otherProps,
  onDone,
  showLinkPartnerButton = false,
  contact_name,
  onClick,
}: LinkPartnerProps) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [allPartners, setAllPartners] = useState<Partner[]>([]);
  const [filteredPartners, setFilteredPartners] = useState<Partner[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [product, setProduct] = useState("owner");
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const methods = useFormContext<CreatePartnerLeadType>();

  const searchPartners = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    try {
      const response = await axios.get(
        "https://xxl6-bbr3-kxzs.m2.xano.io/api:6JkkZBcl:UAT/Partners_listForLead",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "X-Branch": `${import.meta.env.VITE_X_BRANCH}`,
            "X-Data-Source": `${import.meta.env.VITE_X_DATA_SOURCE}`,
          },
        },
      );

      if (!response.data) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const partners =
        response?.data?.map((partner: any) => ({
          id: partner.id.toString(),
          name: partner.name,
          email: partner.email,
          role: partner.status,
        })) || [];

      setAllPartners(partners);
      setFilteredPartners(partners);
    } catch (error) {
      console.error("Failed to fetch partners:", error);
      setError("Failed to fetch partners. Please try again.");
      setAllPartners([]);
      setFilteredPartners([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!value) {
      setFilteredPartners(allPartners);
      return;
    }

    const searchTerm = value.toLowerCase();
    const filtered = allPartners.filter(
      (partner) =>
        partner.name.toLowerCase().includes(searchTerm) ||
        partner.email.toLowerCase().includes(searchTerm),
    );
    setFilteredPartners(filtered);
  }, [value, allPartners]);

  const handlePartnerSelect = (partner: Partner) => {
    setSelectedPartner(partner);
    console.log(partner);
    setValue(partner.name);
    setOpen(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const removeSelectedPartner = () => {
    setSelectedPartner(null);
    setValue("");
  };

  const handleDoneClick = async () => {
    if (showLinkPartnerButton && selectedPartner?.id) {
      if (handleSubmit) {
        handleSubmit(selectedPartner.id);
      }
      if (onDone) {
        // onDone("newlead", selectedPartner.id);
      }

      try {
        const auth = localStorage.getItem("authToken");
        if (auth === null) return;
        const authToken = JSON.parse(auth);
        const payload: AxiosRequestConfig<any> = {
          headers: { Authorization: `Bearer ${authToken}` },
          data: {
            partner_id: selectedPartner.id,
            contacts_id: otherProps?.contact_id,
            relationship_with_partner: product,
          },
        };

        await patchLinkPartner(payload);
        toast.success("Partner linked successfully!");
        navigate(`/leads/partner/${selectedPartner.id}`);
        setOpen(false);
      } catch (error) {
        toast.error("Failed to link partner. Please try again.");
      }
    }
  };

  const handleClick = async () => {
    if (onClick) {
      await onClick();
    }
    setOpen(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setOpen(true);
  };

  return (
    <>
      {loading && <Spinner />}
      <AlertDialog
        onOpenChange={(open) => {
          if (open) {
            searchPartners();
          }
        }}
      >
        <AlertDialogTrigger asChild>
          <div onClick={handleClick}>{children}</div>
        </AlertDialogTrigger>
        <AlertDialogPortal>
          <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
          <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
            <AlertDialogHeader className="flex text-left gap-4 py-5 px-6">
              <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                Link partner
              </AlertDialogTitle>
            </AlertDialogHeader>
            <div className="px-9 overflow-y-auto h-[500px]">
              <div className="space-y-6">
                <div className="space-y-3">
                  <h2 className="text-sm font-medium leading-[16.94px] text-gray-600">
                    How is <span className="text-gray-900">{contact_name}</span>{" "}
                    associated to the partner?
                  </h2>
                  <FormProvider {...methods}>
                    <Tabs
                      className="flex rounded-lg gap-2"
                      value={product}
                      onValueChange={async (tab) => {
                        setProduct(tab);
                        methods.setValue("relationship_with_partner", tab, {
                          shouldValidate: true,
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                      }}
                    >
                      <TabsList className=" sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap">
                        {productOptions.map(
                          (tab: { label: string; value: string }) => (
                            <TabsTrigger
                              key={tab.value}
                              value={tab.value}
                              className={` py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${
                                tab.value === product
                                  ? " bg-navbar-menu-bg text-blue-700"
                                  : " text-black-label bg-[#F1F1F1]"
                              }`}
                            >
                              {tab.label}
                            </TabsTrigger>
                          ),
                        )}
                      </TabsList>
                    </Tabs>
                  </FormProvider>
                </div>
                <div className="">
                  <h2 className="text-sm font-medium leading-[16.94px] mb-4 text-gray-600">
                    Search partner
                  </h2>
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <Input
                      value={value}
                      onChange={handleInputChange}
                      disabled={loading || !!selectedPartner?.id}
                      placeholder="Search partner by name or email"
                      className="pl-10 py-6 text-base bg-white rounded-[30px] border-gray-200"
                      type="text"
                    />
                    {open && value && (
                      <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg max-h-[300px] overflow-y-auto z-50">
                        <div className="p-0 relative">
                          {filteredPartners.map((partner) => (
                            <button
                              key={partner.id}
                              className="flex justify-between items-center w-full p-3 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                handlePartnerSelect(partner);
                              }}
                            >
                              <div className="flex flex-col">
                                <span className="font-medium text-left">
                                  {partner.name}
                                </span>
                                <span className="text-sm text-gray-500 text-left">
                                  {partner.email}
                                </span>
                              </div>
                              <div>{partner.role}</div>
                            </button>
                          ))}
                        </div>
                        <div className="sticky bottom-0 bg-white">
                          <button
                            onClick={() =>
                              navigate(
                                `/lead/partner/createlead?c_id=${otherProps?.contact_id}&relation=${product}`,
                              )
                            }
                            className="w-full font-medium text-sm font-inter text-[#ffffff] p-3 bg-[#165FE3]"
                          >
                            + Add Partner Lead
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {selectedPartner && (
                <div className="mt-4">
                  <div className="flex flex-row justify-between items-center">
                    <h3 className="text-sm text-gray-600 mb-2">You added</h3>
                    <div className="flex">
                      <button onClick={removeSelectedPartner}>
                        <CircleX className="h-4 w-4 mb-2 text-[#c4c4c4]" />
                      </button>
                    </div>
                  </div>
                  <div className="bg-white rounded-lg p-4 border border-gray-200 relative">
                    <div className="">
                      <div className="flex items-center justify-between">
                        <h4 className="text-lg font-medium text-gray-900">
                          {selectedPartner.name}
                        </h4>
                        <div
                          className={`flex items-center ${
                            selectedPartner.role === "lead"
                              ? "text-yellow-600"
                              : "text-green-600"
                          }`}
                        >
                          {selectedPartner.role === "active" && (
                            <Check className="h-4 w-4 mr-1" />
                          )}
                          <span className="text-sm">
                            {selectedPartner.role === "lead"
                              ? "Lead"
                              : "Registered"}
                          </span>
                        </div>
                      </div>
                      <p className="text-gray-500 mt-1">
                        {selectedPartner.email}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {showLinkPartnerButton ? (
              <AlertDialogFooter className="flex items-center gap-2.5 py-2 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                <AlertDialogCancel className="m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700">
                  Cancel
                </AlertDialogCancel>
                <AlertDialogAction
                  className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                  onClick={handleDoneClick}
                  disabled={!selectedPartner?.id || !otherProps?.contact_id}
                >
                  Done
                </AlertDialogAction>
              </AlertDialogFooter>
            ) : (
              <AlertDialogFooter className="flex items-center gap-2.5 py-2 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                <AlertDialogCancel className="m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700">
                  Cancel
                </AlertDialogCancel>
                <AlertDialogAction
                  className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                  onClick={() => {
                    setSelectedPartner(null);
                    setValue("");
                  }}
                  disabled={!selectedPartner?.id}
                >
                  Done
                </AlertDialogAction>
              </AlertDialogFooter>
            )}
          </AlertDialogContent>
        </AlertDialogPortal>
      </AlertDialog>
    </>
  );
}
