import { useState } from "react";
import { useDispatch } from "react-redux";
import { setDocList } from "redux/features/CreateOpportunity";
import { useAppSelector } from "redux/hooks";

interface ContactDetailsProps {
  errors: Record<string, string>;
}

export default function DocumentUploadSteps({ errors }: ContactDetailsProps) {
  const { doc_list } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useDispatch();

  const documents = [
    { id: "5", name: "Offer Letter", required: true },
    { id: "3", name: "Passbook", required: false },
    { id: "1", name: "PAN card", required: false },
    { id: "2", name: "Aadhaar card", required: false },
  ];

  const handleFileUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    docName: string,
    id: string,
  ) => {
    const file = e.target.files?.[0] || null;
    if (!file) return;
    //check if file is loaded is being reloaded
    const doc = doc_list.find((doc) => doc.id === id);
    if (doc) {
      dispatch(
        setDocList(
          doc_list.map((doc) => {
            if (doc.id === id) {
              return { ...doc, document: file };
            }
            return doc;
          }),
        ),
      );
      return;
    }
    dispatch(setDocList([...doc_list, { name: docName, id, document: file }]));
  };

  const DocumentUpload = ({
    documents,
  }: {
    documents: { id: string; name: string; required: boolean }[];
  }) => {
    return (
      <div className="flex relative top-0 flex-col sm:pl-0 pl-10 gap-10 w-full max-w-md">
        {documents.map((doc, index) => {
          const uploadedDoc = doc_list.find((d) => d.id === doc.id);
          return (
            <div
              key={index.toFixed(2)}
              className="flex relative flex-col gap-3 items-start justify-start w-full"
            >
              <div
                className={`flex items-center top-0 flex-col after:right-10 absolute ${index !== documents.length - 1 ? "after:h-28 after:border-blue-700" : ""} after:relative after:content-[''] after:border after:border-dashed`}
              >
                <img
                  className="relative -left-10"
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label htmlFor={doc.id} className="text-sm font-medium">
                {doc.name}
                <small className="text-sm text-[#a19d9d]">
                  {doc.required ? "" : " (Optional) "}
                </small>
              </label>
              <div className="flex gap-2 flex-col w-full">
                <div className="py-1.5 px-3.5 justify-between rounded-md flex gap-8 border">
                  <div className="flex gap-3 items-center overflow-hidden">
                    <img src="/images/remit/file.svg" alt="" />
                    <p className="truncate">
                      {" "}
                      {uploadedDoc?.id
                        ? uploadedDoc?.document?.name
                        : "No file uploaded"}
                    </p>
                  </div>
                  <label
                    htmlFor={doc.id}
                    className="text-[#165FE3] border cursor-pointer border-[#165FE3] text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                  >
                    {uploadedDoc?.document?.name ? "Re-upload" : "Upload"}
                  </label>
                  <input
                    type="file"
                    id={doc.id}
                    name={doc.name}
                    className="hidden"
                    onChange={(e) => handleFileUpload(e, doc.name, doc.id)}
                    accept="image/*,application/pdf"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <form className="flex flex-col gap-10" onSubmit={() => {}}>
      <div className="flex relative top-0 flex-col sm:pl-0 pl-5 gap--8 w-full max-w-md ">
        <DocumentUpload documents={documents} />
      </div>
    </form>
  );
}
