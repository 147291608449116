import { Input, Text } from "components";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from "@/components/ui/accordion";
import {
  setBeneficiaryAccountNumber,
  setBeneficiaryBankAddr,
  setBeneficiaryBankCountry,
  setBeneficiaryBankName,
  setBeneficiaryName,
  setBeneficiarySwiftCode,
  setBeneficiaryType,
} from "../../redux/features/CreateOrder";

interface AddBeneficiaryModalProps {
  setIsEnable: (enabled: boolean) => void;
}

const AddBeneficiaryModal: React.FC<AddBeneficiaryModalProps> = ({
  setIsEnable,
}) => {
  const {
    beneficiary_name,
    beneficiary_bank_name,
    beneficiary_bank_addr,
    beneficiary_bank_country,
    beneficiary_swift_code,
    beneficiary_account_number,
  } = useAppSelector((state) => state.CreateOrder);

  const swiftRegex =
    /^[A-Z]{4}[-]{0,1}[A-Z]{2}[-]{0,1}[A-Z0-9]{2}[-]{0,1}[0-9]{3}$/;

  const dispatch = useAppDispatch();
  const showerror = useAppSelector((state) => state.CreateOrder.showerror);
  const [touchedFields, setTouchedFields] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const isFormValid =
      beneficiary_name.trim() !== "" &&
      beneficiary_bank_name.trim() !== "" &&
      beneficiary_bank_addr.trim() !== "" &&
      beneficiary_bank_country.trim() !== "" &&
      beneficiary_account_number.trim() !== "" &&
      swiftRegex.test(beneficiary_swift_code);

    setIsEnable(isFormValid);
  }, [
    beneficiary_name,
    beneficiary_bank_name,
    beneficiary_bank_addr,
    beneficiary_bank_country,
    beneficiary_swift_code,
    beneficiary_account_number,
    setIsEnable,
  ]);

  const handleBlur = (field: string) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
  };

  return (
    <div className="w-full flex flex-row justify-between gap-4 rounded-lg sm:w-full">
      <div className="bg-white-A700 rounded-lg px-8 flex md:flex-1 flex-col gap-5 items-start justify-start md:px-5 w-full md:w-full sm:w-full">
        <div className="flex flex-col gap-2.5 items-start justify-start w-full sm:w-full">
          <Accordion
            className="w-full sm:w-full"
            type="single"
            collapsible={false}
            value="item-1"
          >
            <AccordionItem value="item-1">
              <AccordionContent className="w-[377px] pl-10 sm:w-full">
                <div className="flex relative flex-col items-start justify-start w-full gap-10 pb-8">
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      Select Beneficiary Type
                    </Text>
                    <div className="flex gap-4">
                      <div className="flex items-center p-4 border border-black rounded-lg">
                        <input
                          type="radio"
                          id="option1"
                          name="beneficiaryType"
                          className="mr-2"
                          onChange={() =>
                            dispatch(setBeneficiaryType("individual"))
                          }
                        />
                        <label htmlFor="option1">Individual</label>
                      </div>

                      <div className="flex items-center p-4 border border-black rounded-lg">
                        <input
                          type="radio"
                          id="option2"
                          name="beneficiaryType"
                          className="mr-2"
                          onChange={() =>
                            dispatch(setBeneficiaryType("institution"))
                          }
                        />
                        <label htmlFor="option2">Institution</label>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      Beneficiary Name
                    </Text>
                    <Input
                      name="beneficiary_name"
                      value={beneficiary_name}
                      onChange={(e: string) => dispatch(setBeneficiaryName(e))}
                      onBlur={() => handleBlur("beneficiary_name")}
                      placeholder="Enter Name"
                      errors={
                        touchedFields["beneficiary_name"] &&
                        beneficiary_name === ""
                          ? ["Beneficiary Name Required"]
                          : []
                      }
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    />
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      Beneficiary Account Number
                    </Text>
                    <Input
                      name="beneficiary_account_number"
                      value={beneficiary_account_number}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryAccountNumber(e))
                      }
                      onBlur={() => handleBlur("beneficiary_account_number")}
                      errors={
                        touchedFields["beneficiary_account_number"] &&
                        beneficiary_account_number === ""
                          ? ["Beneficiary Account Number Required"]
                          : []
                      }
                      placeholder="Enter Account Number"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      Beneficiary Bank Name
                    </Text>
                    <Input
                      name="beneficiary_bank_name"
                      value={beneficiary_bank_name}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryBankName(e))
                      }
                      onBlur={() => handleBlur("beneficiary_bank_name")}
                      errors={
                        touchedFields["beneficiary_bank_name"] &&
                        beneficiary_bank_name === ""
                          ? ["Beneficiary Bank Name Required"]
                          : []
                      }
                      placeholder="Enter Bank Name"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      Beneficiary Bank Address{" "}
                    </Text>
                    <Input
                      name="beneficiary_bank_addr"
                      value={beneficiary_bank_addr}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryBankAddr(e))
                      }
                      onBlur={() => handleBlur("beneficiary_bank_addr")}
                      errors={
                        touchedFields["beneficiary_bank_addr"] &&
                        beneficiary_bank_addr === ""
                          ? ["Beneficiary Bank Address Required"]
                          : []
                      }
                      placeholder="Enter Bank Address"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      Beneficiary Bank Country
                    </Text>
                    <Input
                      name="beneficiary_bank_country"
                      value={beneficiary_bank_country}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryBankCountry(e))
                      }
                      onBlur={() => handleBlur("beneficiary_bank_country")}
                      errors={
                        touchedFields["beneficiary_bank_country"] &&
                        beneficiary_bank_country === ""
                          ? ["Beneficiary Bank Country Required"]
                          : []
                      }
                      placeholder="Enter Bank Country"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${true ? "after:h-20 after:border-blue-700" : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      SWIFT Code
                    </Text>
                    <Input
                      name="beneficiary_swift_code"
                      value={beneficiary_swift_code}
                      onChange={(e: string) =>
                        dispatch(setBeneficiarySwiftCode(e))
                      }
                      errors={
                        touchedFields["beneficiary_swift_code"] &&
                        beneficiary_swift_code === ""
                          ? ["Beneficiary Swift Code Required"]
                          : touchedFields["beneficiary_swift_code"] &&
                              !swiftRegex.test(beneficiary_swift_code)
                            ? ["Please enter a valid Swift Code"]
                            : []
                      }
                      onBlur={() => handleBlur("beneficiary_swift_code")}
                      placeholder="Enter Swift Code"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default AddBeneficiaryModal;
