import { Img, Input, Text } from "components";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  setBeneficiaryAddrLine1,
  setBeneficiaryAddrLine2,
  setBeneficiaryBsbcode,
  setBeneficiaryCity,
  setBeneficiaryCorrespondantBankName,
  setBeneficiaryCorrespondantBankNo,
  setBeneficiaryCountry,
  setBeneficiaryIbanNo,
  setBeneficiaryNationalId,
  setBeneficiaryRoutingNumber,
  setBeneficiaryShortCode,
  setBeneficiaryState,
  setBeneficiaryTransitCode,
  setBeneficiaryZip,
} from "../../redux/features/CreateOrder";

const Step9: React.FC = () => {
  const {
    beneficiary_addr_line_1,
    beneficiary_addr_line_2,
    beneficiary_city,
    beneficiary_state,
    beneficiary_country,
    beneficiary_zip,
    beneficiary_routing_number,
    beneficiary_transit_code,
    beneficiary_bsbcode,
    beneficiary_short_code,
    beneficiary_national_id,
    beneficiary_iban_no,
    beneficiary_correspondant_bank_name,
    beneficiary_correspondant_bank_no,
    PAN,
    customer_name,
    service_partner_id,
  } = useAppSelector((state) => state.CreateOrder);

  const routingRegex = /^\d{9}$/;
  const transitRegex = /^\d{5}$/;
  const bsbCodeRegex = /^\d{3}-\d{3}$/;
  const shortCodeRegex = /^\d{4}$/;
  const ibanNumberRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{7}([A-Z0-9]?){0,16}$/;
  const [touchedFields, setTouchedFields] = useState<{
    [key: string]: boolean;
  }>({});

  const dispatch = useAppDispatch();
  const showerror = useAppSelector((state) => state.CreateOrder.showerror);
  const isMandatory = service_partner_id === 17;

  const handleBlur = (field: string) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
  };

  // check first step field
  const isAddressComplete = !!(
    beneficiary_addr_line_1 &&
    beneficiary_city &&
    beneficiary_state &&
    beneficiary_country &&
    beneficiary_zip
  );

  const isOtherDetailsComplete = !!(
    beneficiary_transit_code &&
    beneficiary_bsbcode &&
    beneficiary_short_code &&
    beneficiary_national_id &&
    beneficiary_iban_no
  );

  return (
    <>
      <div className="w-full flex flex-row justify-between gap-4 rounded-lg sm:w-full">
        <div className="bg-white-A700 rounded-lg flex md:flex-1 flex-col gap-5 items-start justify-start md:px-5 w-full md:w-full sm:w-full">
          <div className="flex flex-col gap-2.5 items-start justify-start w-full sm:w-full">
            <Accordion className="w-full sm:w-full" type="single" collapsible>
              <AccordionItem value="address" className="sm:w-full">
                <AccordionTrigger
                  disabled={!PAN}
                  className=" disabled:opacity-40"
                  style={{
                    backgroundColor: isAddressComplete ? "#F6FFF6" : "#fff",
                  }}
                >
                  <div className="flex justify-between items-center w-full">
                    <div className="flex justify-between">
                      <div className="flex gap-2 items-center">
                        <div
                          style={{ backgroundColor: "black", color: "white" }}
                          className="border border-black rounded-full w-10 h-10 flex items-center justify-center"
                        >
                          1
                        </div>
                        <div className="">
                          Address Details (
                          {isMandatory ? "Required" : "Optional"})
                        </div>
                      </div>
                    </div>
                    {isAddressComplete && (
                      <img
                        src="/images/remit/completeIcon.svg"
                        alt="Completed"
                        className="w-5 h-5 mr-[10px]"
                      />
                    )}
                  </div>
                </AccordionTrigger>
                <AccordionContent className="w-full pl-10 sm:w-full">
                  <div className="flex flex-col items-start justify-start w-full gap-10 pb-8 px-4">
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        What is {customer_name} Home Address ?
                      </Text>
                      <Input
                        name="beneficiary_addr_line_1"
                        value={beneficiary_addr_line_1}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryAddrLine1(e))
                        }
                        onBlur={() => handleBlur("beneficiary_addr_line_1")}
                        errors={
                          isMandatory &&
                          touchedFields["beneficiary_addr_line_1"] &&
                          beneficiary_addr_line_1 === ""
                            ? ["Beneficiary Address Required"]
                            : []
                        }
                        placeholder="Enter Address Line 1"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        What is {customer_name} Address 2 ?
                      </Text>
                      <Input
                        name="beneficiary_addr_line_2"
                        value={beneficiary_addr_line_2}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryAddrLine2(e))
                        }
                        onBlur={() => handleBlur("beneficiary_addr_line_2")}
                        errors={
                          isMandatory &&
                          touchedFields["beneficiary_addr_line_2"] &&
                          beneficiary_addr_line_2 === ""
                            ? ["Beneficiary Address Required"]
                            : []
                        }
                        placeholder="Enter Address Line 2"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        In which city does {customer_name} live?{" "}
                      </Text>
                      <Input
                        name="beneficiary_city"
                        value={beneficiary_city}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryCity(e))
                        }
                        onBlur={() => handleBlur("beneficiary_city")}
                        errors={
                          isMandatory &&
                          touchedFields["beneficiary_city"] &&
                          beneficiary_city === ""
                            ? ["Beneficiary City Required"]
                            : []
                        }
                        placeholder="Enter City"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        In which state does {customer_name} live?
                      </Text>
                      <Input
                        name="beneficiary_state"
                        value={beneficiary_state}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryState(e))
                        }
                        onBlur={() => handleBlur("beneficiary_state")}
                        errors={
                          isMandatory &&
                          touchedFields["beneficiary_state"] &&
                          beneficiary_state === ""
                            ? ["Beneficiary State Required"]
                            : []
                        }
                        placeholder="Enter State"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        In which country does {customer_name} live?
                      </Text>
                      <Input
                        name="beneficiary_country"
                        value={beneficiary_country}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryCountry(e))
                        }
                        onBlur={() => handleBlur("beneficiary_country")}
                        errors={
                          isMandatory &&
                          touchedFields["beneficiary_country"] &&
                          beneficiary_country === ""
                            ? ["Beneficiary Country Required"]
                            : []
                        }
                        placeholder="Enter Country"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${true ? "after:h-20 after:border-blue-700" : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        What is {customer_name} Pincode ?
                      </Text>
                      <Input
                        name="input_Six"
                        value={beneficiary_zip}
                        onChange={(e: string) => {
                          if (/^\d{0,6}$/.test(e)) {
                            dispatch(setBeneficiaryZip(e));
                          }
                        }}
                        onBlur={() => handleBlur("beneficiary_zip")}
                        errors={
                          touchedFields["beneficiary_zip"] && isMandatory
                            ? beneficiary_zip === ""
                              ? ["Beneficiary ZIP is required"]
                              : beneficiary_zip.length < 6
                                ? ["Beneficiary ZIP must be exactly 6 digits"]
                                : undefined
                            : undefined
                        }
                        placeholder="Enter PIN/ZIP code"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        type="number"
                      />
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="other">
                <AccordionTrigger
                  disabled={!PAN}
                  className=" disabled:opacity-40"
                  style={{
                    backgroundColor: isOtherDetailsComplete
                      ? "#F6FFF6"
                      : "#fff",
                  }}
                >
                  <div className="flex justify-between items-center w-full">
                    <div className="flex justify-between">
                      <div className="flex gap-2 items-center">
                        <div
                          style={{ backgroundColor: "black", color: "white" }}
                          className="border border-black rounded-full w-10 h-10 flex items-center justify-center"
                        >
                          2
                        </div>
                        <div className="">
                          Other Details ({isMandatory ? "Required" : "Optional"}
                          )
                        </div>
                      </div>
                    </div>
                    {isOtherDetailsComplete && (
                      <img
                        src="/images/remit/completeIcon.svg"
                        alt="Completed"
                        className="w-5 h-5 mr-[10px]"
                      />
                    )}
                  </div>
                </AccordionTrigger>
                <AccordionContent className="w-full pl-10 sm:w-full">
                  <div className="flex flex-col items-start justify-start w-full gap-10 pb-8 px-4">
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        What is Beneficiary’s Routing number? (Optional)
                      </Text>
                      <Input
                        name="input_Ten"
                        value={beneficiary_routing_number}
                        errors={
                          beneficiary_routing_number &&
                          !routingRegex.test(beneficiary_routing_number)
                            ? ["Please enter a valid Routing Number"]
                            : undefined
                        }
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryRoutingNumber(e))
                        }
                        onBlur={() => handleBlur("beneficiary_routing_number")}
                        placeholder="Enter Routing Number"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        type="number"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Transit Code
                      </Text>
                      <Input
                        name="input_Eleven"
                        value={beneficiary_transit_code}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryTransitCode(e))
                        }
                        onBlur={() => handleBlur("beneficiary_transit_code")}
                        errors={
                          touchedFields["beneficiary_transit_code"] && showerror
                            ? beneficiary_transit_code === ""
                              ? ["Beneficiary Transit Code Required"]
                              : !transitRegex.test(beneficiary_transit_code)
                                ? ["Please enter a valid Transit Code"]
                                : undefined
                            : undefined
                        }
                        placeholder="Enter Transit Code"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        BSB Code
                      </Text>
                      <Input
                        name="beneficiary_bsbcode"
                        value={beneficiary_bsbcode}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryBsbcode(e))
                        }
                        onBlur={() => handleBlur("beneficiary_bsbcode")}
                        errors={
                          touchedFields["beneficiary_bsbcode"] && showerror
                            ? beneficiary_bsbcode === ""
                              ? ["Please enter a valid BSB Code"]
                              : !bsbCodeRegex.test(beneficiary_bsbcode)
                                ? ["Please enter a valid BSB Code"]
                                : undefined
                            : undefined
                        }
                        placeholder="Enter BSB Code"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        <span className="text-black-900_dd font-inter text-left">
                          Short Code (Optional)
                        </span>
                      </Text>
                      <Input
                        name="beneficiary_short_code"
                        value={beneficiary_short_code}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryShortCode(e))
                        }
                        onBlur={() => handleBlur("beneficiary_short_code")}
                        errors={
                          beneficiary_short_code &&
                          !shortCodeRegex.test(beneficiary_short_code)
                            ? ["Please enter a valid Short Code"]
                            : undefined
                        }
                        placeholder="Enter Short Code"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Beneficiary National ID
                      </Text>
                      <Input
                        name="beneficiary_national_id"
                        value={beneficiary_national_id}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryNationalId(e))
                        }
                        onBlur={() => handleBlur("beneficiary_national_id")}
                        errors={
                          touchedFields["beneficiary_national_id"] && showerror
                            ? beneficiary_national_id === ""
                              ? ["Beneficiary National ID Required"]
                              : undefined
                            : undefined
                        }
                        placeholder="Enter Beneficiary National ID"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        IBAN Number
                      </Text>
                      <Input
                        name="beneficiary_iban_no"
                        value={beneficiary_iban_no}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryIbanNo(e))
                        }
                        onBlur={() => handleBlur("beneficiary_iban_no")}
                        errors={
                          touchedFields["beneficiary_iban_no"] && showerror
                            ? beneficiary_iban_no === ""
                              ? ["Beneficiary IBAN Number Required"]
                              : !ibanNumberRegex.test(beneficiary_iban_no)
                                ? ["Please enter a valid IBAN Code"]
                                : undefined
                            : undefined
                        }
                        placeholder="Enter IBAN Number"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        type="text"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        <span className="text-black-900_dd font-inter text-left">
                          Correspondent Bank Name{" "}
                        </span>
                        <span className="text-black-900_dd font-inter text-left font-normal">
                          (optional)
                        </span>
                      </Text>
                      <Input
                        name="input_Sixteen"
                        value={beneficiary_correspondant_bank_name}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryCorrespondantBankName(e))
                        }
                        placeholder="Enter Correspondent bank name"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        type="text"
                      ></Input>
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${true ? "after:h-20 after:border-blue-700 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        <span className="text-black-900_dd font-inter text-left">
                          Correspondent Bank Number{" "}
                        </span>
                        <span className="text-black-900_dd font-inter text-left font-normal">
                          (optional)
                        </span>
                      </Text>
                      <Input
                        name="input_Seventeen"
                        value={beneficiary_correspondant_bank_no}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryCorrespondantBankNo(e))
                        }
                        placeholder="Enter Correspondent Bank Number"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      ></Input>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

Step9.defaultProps = {};

export default Step9;
