import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

import { PaymentType } from "@/lib/validations/schema";
import { AxiosRequestConfig } from "axios";
import React, { useState } from "react";
import {
  PaymentStatusAPI,
  POSTeditPaymentStatusRequestType,
} from "service/apis/CreateOrderapi";
import { toast } from "sonner";

interface AddBeneficiaryModalProps {
  setIsopen: (enabled: boolean) => void;
  paymentData: PaymentType[];
}

const PaymentReviewModal: React.FC<AddBeneficiaryModalProps> = ({
  setIsopen,
  paymentData,
}) => {
  const [activeModalTab, setActiveModalTab] = useState<string>("payment-proof");
  const [isRejectedNotes, setIsRejectedNotes] = useState(false);
  const [rejectedNotes, setRejectedNotes] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleApprove = async (
    order_id: Number,
    ack_no: Number,
    status_AD2: string,
    notes: string,
  ) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<POSTeditPaymentStatusRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        order_id,
        ack_no,
        status_AD2,
        notes,
      },
    };
    try {
      const response = await PaymentStatusAPI(payload);
      if (response?.status === 200) {
        console.log("API resp ====?>>>>", response?.data);
      }
    } catch (error) {
    } finally {
      toast.error("Failed to update partner details");
      setLoading(false);
    }
  };

  const handleRejecte = async (order_id: Number, note: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<POSTAddNotesOrderRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        order_id,
        note,
      },
    };
    try {
      const response = await AddOrderNotesAPI(payload);
      if (response?.status === 200) {
        OrderDetails();
        console.log("API resp ====?>>>>", response?.data);
      }
    } catch (error) {
      toast.error("Failed to update partner details");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-row justify-between gap-4 rounded-lg sm:w-full">
      <div className="bg-white-A700 rounded-lg px-8 flex md:flex-1 flex-col gap-5 items-start justify-start md:px-5 w-full md:w-full sm:w-full">
        <div className="flex flex-col gap-2.5 items-start justify-start w-full sm:w-full">
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg w-full p-6 max-w-full">
              <div className="flex border-b mb-4">
                <button
                  className={`flex-1 text-center py-2 ${activeModalTab === "payment-proof" ? "border-b-2 border-blue-600 font-semibold" : ""}`}
                  onClick={() => setActiveModalTab("payment-proof")}
                >
                  1. Payment Proof
                </button>
                <button
                  className={`flex-1 text-center py-2 ${activeModalTab === "supported-docs" ? "border-b-2 border-blue-600 font-semibold" : ""}`}
                  onClick={() => setActiveModalTab("supported-docs")}
                >
                  2. Supported Documents
                </button>
              </div>

              <div className="h-80 overflow-auto border rounded-lg p-2">
                {paymentData?.[0]?.receipt?.url &&
                  (activeModalTab === "payment-proof" ? (
                    paymentData?.[0]?.receipt?.type === "pdf" ? (
                      <embed
                        src={paymentData?.[0]?.receipt?.url}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <div className="flex justify-center items-center h-full">
                        <img
                          src={paymentData?.[0]?.receipt?.url}
                          alt="Supported Document"
                          className="object-contain max-h-full w-auto"
                        />
                      </div>
                    )
                  ) : paymentData?.[0]?.receipt?.type === "pdf" ? (
                    <embed
                      src={paymentData?.[0]?.receipt?.url}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <div className="flex justify-center items-center h-full">
                      <img
                        src={paymentData?.[0]?.receipt?.url}
                        alt="Supported Document"
                        className="object-contain max-h-full w-auto"
                      />
                    </div>
                  ))}
              </div>

              <div className="flex justify-between items-center border-t pt-4 mt-4">
                <div className="flex gap-2">
                  <button
                    className="px-4 py-2 border rounded-lg text-gray-600 hover:bg-gray-200 focus:outline-none"
                    onClick={() => setIsopen(false)}
                  >
                    Cancel
                  </button>
                  {/* <button className="px-4 py-2 bg-white-600 border border-blue-500 text-white rounded-lg hover:bg-blue-700 focus:outline-none">
                                        Preview
                                    </button> */}
                </div>
                <div className="flex gap-2">
                  <button
                    className="px-4 py-2 border border-red-500 text-red-500 rounded-lg hover:bg-red-100 focus:outline-none"
                    onClick={() => setIsRejectedNotes(true)}
                  >
                    Reject
                  </button>
                  <button className="px-4 py-2 bg-blue-600 text-[#fff] rounded-lg hover:bg-blue-500 focus:outline-none">
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertDialog open={isRejectedNotes} onOpenChange={setIsRejectedNotes}>
        <AlertDialogPortal>
          <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
          <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
            <form className="max-w-full">
              <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                  Note (Visible to the moneeflo and its partners)
                </AlertDialogTitle>
                <div className="flex flex-col gap-4">
                  <textarea
                    value={rejectedNotes}
                    placeholder="Enter note"
                    className="border border-gray-400 rounded-lg p-4 w-full h-[250px] bg-white"
                    onChange={(e) => setRejectedNotes(e.target.value)}
                    rows={5}
                  />
                </div>
              </AlertDialogHeader>
              <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                <AlertDialogCancel
                  className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                  asChild
                >
                  <button onClick={() => setIsRejectedNotes(false)}>
                    Cancel
                  </button>
                </AlertDialogCancel>
                <AlertDialogAction
                  type="submit"
                  // onClick={() => handleTCSSave(parseInt(param?.id), "", parseInt(tcsValue))}
                  onClick={() => setIsRejectedNotes(false)}
                  className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                >
                  Send
                </AlertDialogAction>
              </AlertDialogFooter>
            </form>
          </AlertDialogContent>
        </AlertDialogPortal>
      </AlertDialog>
    </div>
  );
};

export default PaymentReviewModal;
