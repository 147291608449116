"use client";

import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { FileIcon } from "lucide-react";
import type React from "react";

interface FileUploadFormData {
  reason: string;
  documentName?: string;
  file: FileList;
}

interface FileUploadProps {
  children: React.ReactNode;
  onSubmit: (data: FileUploadFormData) => void;
  isAdditionalDoc?: boolean;
}

export default function FileUploadDialog({
  children,
  onSubmit,
  isAdditionalDoc = false,
}: Readonly<FileUploadProps>) {
  const { register, handleSubmit, watch, setValue } =
    useForm<FileUploadFormData>();
  const selectedReason = watch("reason");

  const handleFormSubmit = (data: FileUploadFormData) => {
    onSubmit(data);
    // alert(JSON.stringify(data, null, 2))
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay className="bg-black-900_01/60 animate-overlayShow fixed inset-0" />
        <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-md animate-contentShow">
          <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10">
            <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-gray-900">
              Select the document to be uploaded
            </AlertDialogTitle>
          </AlertDialogHeader>

          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="px-10 pb-4"
          >
            <div className="space-y-6">
              {/* Reason Select */}
              <div className="space-y-2">
                <Label htmlFor="reason">Select reason</Label>
                <Select
                  onValueChange={(value) => {
                    if (value !== "additional") {
                      setValue("documentName", "");
                    }
                    setValue("reason", value);
                  }}
                >
                  <SelectTrigger className="w-full bg-white">
                    <SelectValue placeholder="Select reason" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="kyc">KYC Documents</SelectItem>
                    <SelectItem value="order">Order Documentation</SelectItem>
                    <SelectItem value="additional">
                      Additional Documentation
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>

              {/* Conditional Document Name Input */}
              {(isAdditionalDoc || selectedReason === "additional") && (
                <div className="space-y-2">
                  <Label htmlFor="documentName">Document Name</Label>
                  <Input
                    id="documentName"
                    placeholder="Enter document name"
                    {...register("documentName", { required: true })}
                  />
                </div>
              )}

              {/* File Upload */}
              <div className="space-y-2">
                <Label>Upload the documents</Label>
                <div className="border-2 border-dashed rounded-lg p-4 relative">
                  <Input
                    type="file"
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    {...register("file", { required: true })}
                  />
                  <div className="flex items-center justify-between text-gray-500">
                    <div className="flex items-center gap-2">
                      <FileIcon className="h-5 w-5" />
                      <span>
                        {watch("file")?.length
                          ? watch("file")[0].name
                          : "No file chosen"}
                      </span>
                    </div>
                    <span className="text-blue-600 font-medium">Upload</span>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-50">
            <AlertDialogCancel className="rounded-lg m-0 border-blue-700 border py-[11px] px-4 text-blue-700">
              Close
            </AlertDialogCancel>
            <AlertDialogAction
              type="submit"
              onClick={handleSubmit(handleFormSubmit)}
              className="rounded-lg border-blue-700 py-[11px] px-4 text-white-A700 bg-blue-700 border"
            >
              Save
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}
