import { Option } from "components/Form/form";
import { z } from "zod";

export const step1Schema = z.object({
  corporate_type: z.string(),
  company_name: z.string(),
  company_website: z.string().url(),
  company_state: z.string(),
  company_city: z.string(),
});

export const step2Schema = z.object({
  services: z.array(z.string()),
  branch: z.string(),
  assigned_to: z.string(),
});

export const step3Schema = z.object({
  contacted_type: z.enum(["i_contacted", "corporate_contacted"]),
  channelofcontact_type: z.string(),
  meeting_time: z.string(),
  meeting_members: z.array(z.string()),
  contacted_to: z.string(),
  notes: z.string(),
  channel_of_interaction_id: z.string(),
});

const extraSchema = z.object({
  relationship_with_company: z.string(),
  contact_id: z.string(),
});

export type CreateCorporateLeadType = z.infer<
  typeof step1Schema &
    typeof step2Schema &
    typeof step3Schema &
    typeof extraSchema
>;

export const defaultValues: CreateCorporateLeadType = {
  corporate_type: "",
  company_name: "",
  company_city: "",
  company_state: "",
  company_website: "",
  services: ['forex'],
  branch: "",
  assigned_to: "",
  contacted_type: "i_contacted",
  channelofcontact_type: "",
  meeting_time: new Date().toISOString(),
  meeting_members: [],
  contacted_to: "",
  notes: "",
  channel_of_interaction_id: "",
  contact_id: "",
  relationship_with_company: "",
};

export const coporate_type: Option[] = [
  { label: "SMB", value: "SMB" },
  { label: "Enterprise", value: "Enterprise" },
];

export const services_options: Option[] = [
  { label: "Forex", value: "forex" },
  { label: "Visa", value: "visa" },
  { label: "Travel", value: "travel" },
];

export const channel_of_contact: { [key: string]: Option[] } = {
  i_contacted: [
    { label: "Meeting", value: "meeting" },
    { label: "Call", value: "phone" },
    { label: "Email", value: "email" },
    { label: "Chat", value: "in_person" },
  ],
  corporate_contacted: [
    { label: "Email", value: "email" },
    { label: "Call", value: "phone" },
    { label: "Meeting", value: "meeting" },
  ],
};
