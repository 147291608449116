import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { ColumnDef } from "@tanstack/react-table";
import { Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import React, { useEffect, useState } from "react";
import {
  setBeneficiaryAccountNumber,
  setBeneficiaryBankAddr,
  setBeneficiaryBankCountry,
  setBeneficiaryBankName,
  setBeneficiaryList,
  setBeneficiaryName,
  setBeneficiarySwiftCode,
  setBeneficiaryType,
} from "redux/features/CreateOrder";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AddBeneficiaryModal from "./addBeneficiaries";

import { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import {
  getCustomer,
  GetCustomerRequestType,
  postBeneficiaryEntry,
  postBeneficiaryEntryRequestType,
} from "service/apis/CreateOrderapi";

export const columns: ColumnDef<any>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Beneficiary Name" />
    ),
    cell: ({ row }) => <div>{row.getValue("name") || "N/A"}</div>,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "city",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Location" />
    ),
    cell: ({ row }) => {
      const city = row?.original?.bank_addr || "N/A";
      const country = row?.original?.bank_country || "N/A";
      return (
        <div className="flex flex-col gap-2">
          <p className="font-normal capitalize text-sm leading-[17px] text-black-label">
            {city}
          </p>
          <p className="font-normal capitalize text-sm leading-[14.5px] text-[#999999]">
            {country}
          </p>
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: "account_number",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="A/C number" />
    ),
    cell: ({ row }) => <div>{row.getValue("account_number") || "N/A"}</div>,
    enableSorting: false,
  },
  {
    accessorKey: "swift_code",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="SWIFT Code" />
    ),
    cell: ({ row }) => <div>{row.getValue("swift_code") || "N/A"}</div>,
    enableSorting: false,
  },
];

const Step8: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isEnable, setIsEnable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const swiftRegex =
    /^[A-Z]{4}[-]{0,1}[A-Z]{2}[-]{0,1}[A-Z0-9]{2}[-]{0,1}[0-9]{3}$/;
  const {
    beneficiary_name,
    beneficiary_bank_name,
    beneficiary_bank_addr,
    beneficiary_addr_line_1,
    beneficiary_addr_line_2,
    beneficiary_bank_country,
    beneficiary_swift_code,
    beneficiary_type,
    customers_id,
    beneficiary_city,
    beneficiary_state,
    beneficiary_country,
    beneficiary_zip,
    beneficiary_account_number,
    beneficiary_routing_number,
    beneficiary_transit_code,
    beneficiary_bsbcode,
    beneficiary_short_code,
    beneficiary_national_id,
    beneficiary_iban_no,
    beneficiary_correspondant_bank_name,
    beneficiary_correspondant_bank_no,
    beneficiary_list,
    PAN,
  } = useAppSelector((state) => state.CreateOrder);
  const [rowSelected, setRowSelected] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const filteredBeneficiaries = beneficiary_list?.filter((beneficiary) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      beneficiary?.name?.toLowerCase()?.includes(searchLower) ||
      beneficiary?.city?.toLowerCase()?.includes(searchLower) ||
      beneficiary?.country?.toLowerCase()?.includes(searchLower)
    );
  });

  const handleAction = (rowData: any) => {
    setRowSelected(rowData?.id);
    if (rowSelected === rowData?.id) {
      setRowSelected("");
      dispatch(setBeneficiaryType(""));
      dispatch(setBeneficiaryName(""));
      dispatch(setBeneficiaryAccountNumber(""));
      dispatch(setBeneficiaryBankName(""));
      dispatch(setBeneficiaryBankAddr(""));
      dispatch(setBeneficiaryBankCountry(""));
      dispatch(setBeneficiarySwiftCode(""));
      return;
    }
    dispatch(setBeneficiaryType(rowData?.beneficiaryType));
    dispatch(setBeneficiaryName(rowData?.name));
    dispatch(setBeneficiaryAccountNumber(rowData?.account_number));
    dispatch(setBeneficiaryBankName(rowData?.bank_name));
    dispatch(setBeneficiaryBankAddr(rowData?.bank_addr));
    dispatch(setBeneficiaryBankCountry(rowData?.country));
    dispatch(setBeneficiarySwiftCode(rowData?.swift_code));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    dispatch(setBeneficiaryType(""));
    dispatch(setBeneficiaryName(""));
    dispatch(setBeneficiaryAccountNumber(""));
    dispatch(setBeneficiaryBankName(""));
    dispatch(setBeneficiaryBankAddr(""));
    dispatch(setBeneficiaryBankCountry(""));
    dispatch(setBeneficiarySwiftCode(""));
  };

  const fetchCustomer = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetCustomerRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        pan_no: PAN.toUpperCase(),
      },
    };

    getCustomer(payload)
      .then((res) => {
        dispatch(setBeneficiaryList(res?.data?.beneficiary));
      })
      .catch((err) => {
        dispatch(setBeneficiaryList([]));
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
  };

  // postBeneficiaryEntry
  const fetchBeneficiaryEntry = (): void | null => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<postBeneficiaryEntryRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        name: beneficiary_name,
        addr_line_1: beneficiary_addr_line_1,
        addr_line_2: beneficiary_addr_line_2,
        city: beneficiary_city,
        state: beneficiary_state,
        country: beneficiary_country,
        zip: beneficiary_zip,
        account_number: beneficiary_account_number,
        bank_name: beneficiary_bank_name,
        bank_addr: beneficiary_bank_addr,
        bank_country: beneficiary_bank_country,
        swift_code: beneficiary_swift_code,
        routing_number: beneficiary_routing_number,
        transit_code: beneficiary_transit_code,
        bsbcode0: beneficiary_bsbcode,
        short_code: beneficiary_short_code,
        national_id: beneficiary_national_id,
        iban_no: beneficiary_iban_no,
        correspondant_bank_name: beneficiary_correspondant_bank_name,
        correspondant_bank_no: beneficiary_correspondant_bank_no,
        Nickname: beneficiary_name,
        beneficiaryType: beneficiary_type,
        customers_id: customers_id,
      },
    };

    postBeneficiaryEntry(payload)
      .then((res) => {
        toast.success("Quote send successfully");
        fetchCustomer();
        handleCloseModal();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(setBeneficiaryType(""));
        dispatch(setBeneficiaryName(""));
        dispatch(setBeneficiaryAccountNumber(""));
        dispatch(setBeneficiaryBankName(""));
        dispatch(setBeneficiaryBankAddr(""));
        dispatch(setBeneficiaryBankCountry(""));
        dispatch(setBeneficiarySwiftCode(""));
      });
  };

  const validateInputs = (): boolean => {
    if (
      !beneficiary_name ||
      !beneficiary_account_number ||
      !beneficiary_bank_name ||
      !beneficiary_bank_addr ||
      !beneficiary_bank_country
    ) {
      toast.error("Please fill all required fields");
      return false;
    }
    if (!swiftRegex.test(beneficiary_swift_code)) {
      toast.error("Invalid SWIFT code");
      return false;
    }
    return true;
  };

  const handleonSubmit = () => {
    if (validateInputs()) {
      fetchBeneficiaryEntry();
    }
  };

  return (
    <div className="flex h-full gap-2 w-full flex-col">
      <div className="flex flex-row md:flex-col w-full justify-between items-center px-5 py-3 bg-white shadow-sm gap-2">
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Search beneficiary here"
            style={{ borderRadius: "20px" }}
            className="rounded-lg border border-gray-300 px-4 py-2 w-[488px] md: w-full focus:outline-none focus:ring focus:ring-blue-200"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <button
          style={{ color: "#fff" }}
          className="text-white rounded-lg py-2 px-4 bg-blue-700 font-semibold text-sm hover:bg-blue-600"
          onClick={() => handleModalOpen()}
        >
          Add Beneficiary
        </button>
      </div>

      <div className="rounded-lg flex-1 flex-col px-5 py-5 w-full shadow-sm">
        <h1 className="mb-2">Select from previous beneficiaries</h1>
        {isLoading ? (
          <Spinner />
        ) : filteredBeneficiaries?.length > 0 ? (
          <div className="overflow-auto">
            <div className=" h-full border p-1 rounded-lg">
              <DataTable
                onRowClick={handleAction}
                data={filteredBeneficiaries}
                columns={columns}
                isToolbarVisible={false}
                highlightRow={!!rowSelected}
                isPaginationVisible={filteredBeneficiaries?.length > 5}
                isPageCountVisible={false}
                searchData={{
                  placeholder: "Search beneficiary",
                  columns: ["beneficiary_name"],
                }}
              />
            </div>
          </div>
        ) : (
          <p>No beneficiaries found. Please add one.</p>
        )}
      </div>

      <div className="flex float-right absolute -top-3 -right-4 gap-2">
        <AlertDialog open={isModalOpen}>
          {/* <AlertDialogTrigger onClick={handleCloseModal}>
            <div className=" cursor-pointer ">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1689_36824)">
                  <path
                    d="M3.83594 4.82812H3.0026C2.56058 4.82813 2.13665 5.00372 1.82409 5.31628C1.51153 5.62884 1.33594 6.05276 1.33594 6.49479V13.9948C1.33594 14.4368 1.51153 14.8607 1.82409 15.1733C2.13665 15.4859 2.56058 15.6615 3.0026 15.6615H10.5026C10.9446 15.6615 11.3686 15.4859 11.6811 15.1733C11.9937 14.8607 12.1693 14.4368 12.1693 13.9948V13.1615"
                    stroke="#165FE3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.3333 3.16676L13.8333 5.66676M14.9875 4.48759C15.3157 4.15938 15.5001 3.71424 15.5001 3.25009C15.5001 2.78594 15.3157 2.34079 14.9875 2.01259C14.6593 1.68438 14.2142 1.5 13.75 1.5C13.2858 1.5 12.8407 1.68438 12.5125 2.01259L5.5 9.00009V11.5001H8L14.9875 4.48759Z"
                    stroke="#165FE3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1689_36824">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </AlertDialogTrigger> */}
          <AlertDialogPortal>
            <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
            <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl w-full animate-contentShow sm:max-w-[90%]">
              <AlertDialogHeader className="flex text-left border-b  rounded-t py-5 px-10 ">
                <AlertDialogTitle className="font-bold capitalize p-0 text-lg text-black-label">
                  Add Beneficiary
                </AlertDialogTitle>
              </AlertDialogHeader>
              <div className="h-[500px] overflow-auto w-full">
                <AddBeneficiaryModal setIsEnable={setIsEnable} />
              </div>
              <AlertDialogFooter className="flex justify-start items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                <AlertDialogCancel
                  className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                  asChild
                >
                  <button onClick={handleCloseModal}>Cancel</button>
                </AlertDialogCancel>
                <AlertDialogAction
                  className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                  asChild
                >
                  <button
                    className=""
                    disabled={!isEnable}
                    onClick={handleonSubmit}
                  >
                    Add Beneficiary
                  </button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogPortal>
        </AlertDialog>
      </div>
    </div>
  );
};

export default Step8;
