import React from "react";
import { Check, Search, X } from "lucide-react";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useEffect, useRef, useState } from "react";
import { AxiosRequestConfig } from "axios";
import { toast } from "sonner";
import { Spinner } from "components";
import { useNavigate } from "react-router-dom";
import {
  patchLinkCorporate,
  PatchLinkCorporateReqType,
  patchLinkPartner,
} from "service/apis/Opportunities";
import axios from "axios";
import { CreatePartnerLeadType } from "pages/CreatePartnerLead/schema";
import { FormProvider, useFormContext } from "react-hook-form";
import { CreateCorporateLeadType } from "pages/CreateCorporateLead/schema";

interface LinkCorporateProps {
  readonly children: React.ReactNode;
  readonly handleSubmit?: (partnerId: string) => void;
  readonly otherProps?: any;
  readonly onDone?: () => void;
  readonly showLinkPartnerButton?: boolean;
  readonly contact_name: string;
  readonly onClick?: () => Promise<void>;
}

export interface Corporate {
  id: string;
  name: string;
  address: string;
  status: string;
  lead_status: string;
  email: string;
  role: string;
}

const productOptions = [
  { label: "Owner/Director", value: "owner" },
  // { label: "Coordinator", value: "coordinator" },
  { label: "Employee", value: "employee" },
  // { label: "Other", value: "other" },
];

export default function LinkCorporate({
  children,
  handleSubmit,
  otherProps,
  onDone,
  showLinkPartnerButton = false,
  contact_name,
  onClick,
}: LinkCorporateProps) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [allCompany, setAllCompany] = useState<Corporate[]>([]);
  const [filteredCompany, setFilteredCompany] = useState<Corporate[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [product, setProduct] = useState("owner");
  const [selectedCompany, setSelectedCompany] = useState<Corporate | null>(
    null,
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const methods = useFormContext<CreateCorporateLeadType>();

  const searchPartners = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    try {
      const response = await axios.get(
        "https://xxl6-bbr3-kxzs.m2.xano.io/api:AtzopIo1:UAT/linkCorporateList",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "X-Branch": `${import.meta.env.VITE_X_BRANCH}`,
            "X-Data-Source": `${import.meta.env.VITE_X_DATA_SOURCE}`,
          },
        },
      );

      if (!response.data) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const companies =
        response?.data?.map((company: any) => ({
          id: company.id.toString(),
          name: company.company_name,
          email: company.email,
          address: company.address,
          status: company.status,
          lead_status: company.lead_status,
          role: company.status,
        })) || [];
      console.log(companies);
      setAllCompany(companies);
      setFilteredCompany(companies);
    } catch (error) {
      setError("Failed to fetch partners. Please try again.");
      setAllCompany([]);
      setFilteredCompany([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!value) {
      setFilteredCompany(allCompany);
      return;
    }

    const searchTerm = value?.toLowerCase();
    const filtered = allCompany.filter(
      (company) =>
        company?.name?.toLowerCase().includes(searchTerm) ||
        company?.email?.toLowerCase().includes(searchTerm),
    );
    setFilteredCompany(filtered);
  }, [value, allCompany]);

  const handlePartnerSelect = (partner: Corporate) => {
    setSelectedCompany(partner);
    setValue(partner.name);
    setOpen(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const removeSelectedCompany = () => {
    setSelectedCompany(null);
    setValue("");
  };

  const handleDoneClick = async () => {
    if (showLinkPartnerButton && selectedCompany?.id) {
      if (handleSubmit) {
        handleSubmit(selectedCompany.id);
      }

      try {
        const auth = localStorage.getItem("authToken");
        if (auth === null) return;
        const authToken = JSON.parse(auth);
        const payload: AxiosRequestConfig<PatchLinkCorporateReqType> = {
          headers: { Authorization: `Bearer ${authToken}` },
          data: {
            coorporate_company_id: selectedCompany.id,
            contacts_id: otherProps?.contact_id,
            relationship_with_company: product,
          },
        };

        await patchLinkCorporate(payload);
        toast.success("Corporate linked successfully!");
        navigate(`/leads/corporate/${selectedCompany.id}`);
        setOpen(false);
      } catch (error) {
        toast.error("Failed to link partner. Please try again.");
      }
    }
  };

  const handleClick = async () => {
    if (onClick) {
      await onClick();
    }
    setOpen(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setOpen(true);
  };

  return (
    <>
      {loading && <Spinner />}
      <AlertDialog
        onOpenChange={(open) => {
          if (open) {
            searchPartners();
          }
        }}
      >
        <AlertDialogTrigger asChild>
          <div onClick={handleClick}>{children}</div>
        </AlertDialogTrigger>
        <AlertDialogPortal>
          <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
          <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
            <AlertDialogHeader className="flex text-left gap-4 py-5 px-6">
              <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                Link corporate
              </AlertDialogTitle>
            </AlertDialogHeader>
            <div className="px-9 overflow-y-auto h-[500px]">
              <div className="space-y-6">
                <div className="space-y-3">
                  <h2 className="text-sm font-medium leading-[16.94px] text-gray-600">
                    How is <span className="text-gray-900">{contact_name}</span>{" "}
                    associated to the corporate?
                  </h2>
                  <FormProvider {...methods}>
                    <Tabs
                      className="flex rounded-lg gap-2"
                      value={product}
                      onValueChange={async (tab) => {
                        setProduct(tab);
                        methods.setValue("relationship_with_company", tab, {
                          shouldValidate: true,
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                      }}
                    >
                      <TabsList className=" sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap">
                        {productOptions.map(
                          (tab: { label: string; value: string }) => (
                            <TabsTrigger
                              key={tab.value}
                              value={tab.value}
                              className={` py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${
                                tab.value === product
                                  ? " bg-navbar-menu-bg text-blue-700"
                                  : " text-black-label bg-[#F1F1F1]"
                              }`}
                            >
                              {tab.label}
                            </TabsTrigger>
                          ),
                        )}
                      </TabsList>
                    </Tabs>
                  </FormProvider>
                </div>
                <div className="">
                  <h2 className="text-sm font-medium leading-[16.94px] mb-4 text-gray-600">
                    Search corporate
                  </h2>
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <Input
                      value={value}
                      onChange={handleInputChange}
                      disabled={loading || !!selectedCompany?.id}
                      placeholder="Search corporate by name or email"
                      className="pl-10 py-6 text-base bg-white rounded-[30px] border-gray-200"
                      type="text"
                    />
                    {open && value && (
                      <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg max-h-[300px] overflow-y-auto z-50">
                        <div className="p-0 relative">
                          {filteredCompany.map((partner) => (
                            <button
                              key={partner.id}
                              className="flex odd:bg-[#F9F9F9] justify-between items-center w-full p-3 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                handlePartnerSelect(partner);
                              }}
                            >
                              <div className="flex flex-col">
                                <span className="font-medium text-left">
                                  {partner.name}
                                </span>
                                <span className="text-sm text-gray-500 text-left">
                                  {partner.email}
                                </span>
                              </div>
                              <div>
                                {partner.role === "Active" ? (
                                  <div className="flex items-center gap-2 text-xs font-normal text-[#999999] leading-[14.4px]">
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 0C2.694 0 0 2.694 0 6C0 9.306 2.694 12 6 12C9.306 12 12 9.306 12 6C12 2.694 9.306 0 6 0ZM8.868 4.62L5.466 8.022C5.382 8.106 5.268 8.154 5.148 8.154C5.028 8.154 4.914 8.106 4.83 8.022L3.132 6.324C2.958 6.15 2.958 5.862 3.132 5.688C3.306 5.514 3.594 5.514 3.768 5.688L5.148 7.068L8.232 3.984C8.406 3.81 8.694 3.81 8.868 3.984C9.042 4.158 9.042 4.44 8.868 4.62Z"
                                        fill="#3FB73D"
                                      />
                                    </svg>
                                    Registered
                                  </div>
                                ) : (
                                  partner.role
                                )}
                              </div>
                            </button>
                          ))}
                        </div>
                        <div className="sticky bottom-0 bg-white">
                          <button
                            onClick={() => {
                              navigate(
                                `/lead/corporate/createlead?c_id=${otherProps?.contact_id}`,
                                {
                                  state: {
                                    contact_id: otherProps?.contact_id,
                                    relation: product,
                                    name: value
                                  },
                                },
                              );
                            }}
                            className="w-full font-medium text-sm font-inter text-[#ffffff] p-3 bg-[#165FE3]"
                          >
                            + Add Corporate Lead
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {selectedCompany && (
                <div className="mt-4">
                  <h3 className="text-sm text-gray-600 mb-2">You added</h3>
                  <div className="bg-white rounded-lg p-4 border border-gray-200 relative">
                    <button
                      onClick={removeSelectedCompany}
                      className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
                    >
                      <X className="h-4 w-4" />
                    </button>
                    <div className="pr-8">
                      <div className="flex items-center justify-between">
                        <h4 className="text-lg font-medium text-gray-900">
                          {selectedCompany.name}
                        </h4>
                        <div
                          className={`flex items-center ${
                            selectedCompany.role === "lead"
                              ? "text-yellow-600"
                              : "text-green-600"
                          }`}
                        >
                          {selectedCompany.role === "active" && (
                            <Check className="h-4 w-4 mr-1" />
                          )}
                          <span className="text-sm">
                            {selectedCompany.role === "lead" ? (
                              "Lead"
                            ) : (
                              <div className="flex items-center gap-2">
                                <svg
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6 0C2.694 0 0 2.694 0 6C0 9.306 2.694 12 6 12C9.306 12 12 9.306 12 6C12 2.694 9.306 0 6 0ZM8.868 4.62L5.466 8.022C5.382 8.106 5.268 8.154 5.148 8.154C5.028 8.154 4.914 8.106 4.83 8.022L3.132 6.324C2.958 6.15 2.958 5.862 3.132 5.688C3.306 5.514 3.594 5.514 3.768 5.688L5.148 7.068L8.232 3.984C8.406 3.81 8.694 3.81 8.868 3.984C9.042 4.158 9.042 4.44 8.868 4.62Z"
                                    fill="#3FB73D"
                                  />
                                </svg>
                                Registered
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                      <p className="text-gray-500 mt-1">
                        {selectedCompany.email}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {showLinkPartnerButton ? (
              <AlertDialogFooter className="flex items-center gap-2.5 py-2 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                <AlertDialogCancel className="m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700">
                  Cancel
                </AlertDialogCancel>
                <AlertDialogAction
                  className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                  onClick={handleDoneClick}
                  disabled={!selectedCompany?.id || !otherProps?.contact_id}
                >
                  Done
                </AlertDialogAction>
              </AlertDialogFooter>
            ) : (
              <AlertDialogFooter className="flex items-center gap-2.5 py-2 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                <AlertDialogCancel className="m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700">
                  Cancel
                </AlertDialogCancel>
                <AlertDialogAction
                  className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                  onClick={() => {
                    setSelectedCompany(null);
                    setValue("");
                  }}
                  disabled={!selectedCompany?.id}
                >
                  Done
                </AlertDialogAction>
              </AlertDialogFooter>
            )}
          </AlertDialogContent>
        </AlertDialogPortal>
      </AlertDialog>
    </>
  );
}
