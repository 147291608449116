import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { LEVEL_2 } from "common/constants";
import { checkLevel } from "common/rules";
import {
  formatUnixTimestamp,
  getRelativeDate,
} from "pages/Opportunities/utils/featureRule";
import { toast } from "sonner";

interface IProps {
  children: React.ReactNode;
  fetchFn: () => Promise<void>;
  docs: {
    doc_id: string;
    filename: string;
    file: string;
    date: string;
  }[];
}

const DocType = {
  "KYC Documents": [1, 2, 3, 4],
  "Transactional Documents": [5, 6, 7, 8, 9, 10],
};

const DocumentModal = ({ children, fetchFn, docs }: IProps) => {
  const segregateDocs = (docs: any) => {
    const segregatedDocs: any = {};
    docs.forEach((doc: any) => {
      if (DocType["KYC Documents"].includes(doc?.doc_id)) {
        if (!segregatedDocs["KYC Documents"]) {
          segregatedDocs["KYC Documents"] = [];
        }
        segregatedDocs["KYC Documents"].push(doc);
      }
      if (DocType["Transactional Documents"].includes(doc?.doc_id)) {
        if (!segregatedDocs["Transactional Documents"]) {
          segregatedDocs["Transactional Documents"] = [];
        }
        segregatedDocs["Transactional Documents"].push(doc);
      }
    });
    return segregatedDocs;
  };

  const handleView = (file: string) => {
    const win = window.open(file, "_blank");
    win?.focus();
  };

  const handleDownload = async (fileUrl: string, fileName?: string) => {
    try {
      // Fetch the file
      const response = await fetch(fileUrl);

      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Determine the filename
      const finalFileName = fileName ?? "document";

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = finalFileName;

      // Append to the document, click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL object
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Download failed:", error);
      // Optionally show an error message to the user
      toast.error("Failed to download the file");
    }
  };
  return (
    <AlertDialog
      onOpenChange={(open) => {
        if (open) {
          fetchFn();
        }
      }}
    >
      <AlertDialogTrigger>{children}</AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
        <AlertDialogContent className="bg-white-A700 rounded-lg gap-0 p-0 max-w-2xl animate-contentShow">
          <AlertDialogHeader className="flex text-left gap-4 py-5 px-6">
            <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
              Documents
            </AlertDialogTitle>
          </AlertDialogHeader>
          <div className="bg-[#EBEEF5] p-2.5">
            <div className="bg-white-A700 p-5 rounded-lg max-h-[500px] flex flex-col gap-6 overflow-auto">
              {docs.length === 0 ? (
                <p className="">No documents available</p>
              ) : (
                Object.keys(segregateDocs(docs)).map((key) => (
                  <div key={key} className="flex flex-col gap-2">
                    <p className="text-sm leading-[19.2px] font-normal text-[#999999]">
                      {key}
                    </p>
                    <div className="flex flex-col gap-2">
                      {segregateDocs(docs)[key].map((doc: any) => (
                        <div
                          key={doc?.id}
                          className="p-5 rounded-lg flex sm:flex-col sm:gap-5 sm:items-start bg-[#FAFAFA] justify-between items-center"
                        >
                          <div className="flex justify-between flex-col">
                            <div className="text-sm leading-[21px] font-medium text-black-label">
                              {doc.filename}
                            </div>
                            <div className="text-xs leading-[18px] font-normal text-[#999999]">
                              {formatUnixTimestamp(parseInt(doc.date))}
                            </div>
                          </div>
                          <div className="text-lg font-bold text-black-label flex gap-4 items-center">
                            <button
                              onClick={() => handleView(doc?.file)}
                              className="py-2 px-[25px] border-blue-700 text-blue-700 rounded-lg border text-sm leading-[19.6px] font-semibold "
                            >
                              View
                            </button>
                            {!checkLevel(LEVEL_2) && (
                              <button
                                onClick={() => {
                                  handleDownload(doc?.file, doc?.filename);
                                }}
                                className="py-2 px-[25px] border-blue-700 text-white-A700 bg-blue-700 rounded-lg border flex items-center gap-2.5 text-sm leading-[19.6px] font-semibold "
                              >
                                Download
                                <svg
                                  width="14"
                                  height="18"
                                  viewBox="0 0 14 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.84375 4.23944C0.84375 2.23461 2.46899 0.609375 4.47382 0.609375H9.51471C9.77199 0.609375 10.0172 0.718567 10.1893 0.909799L13.7698 4.88811C13.9198 5.05476 14.0027 5.27101 14.0027 5.49521V13.7684C14.0027 15.7732 12.3775 17.3984 10.3727 17.3984H4.47382C2.46899 17.3984 0.84375 15.7732 0.84375 13.7684V4.23944Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M13.9998 6.05448V5.49521C13.9998 5.27101 13.9168 5.05476 13.7668 4.88811L10.1863 0.909799C10.0142 0.718567 9.76904 0.609375 9.51175 0.609375H8.55469V2.42441C8.55469 4.42924 10.18 6.05448 12.1848 6.05448H13.9998Z"
                                    fill="#C4D7F9"
                                  />
                                  <path
                                    d="M8.69381 11.9197C8.48432 11.7102 8.23377 11.7678 8.02429 11.9773L7.30437 12.7188V9.33084C7.30437 9.03467 7.10901 8.78906 6.81284 8.78906C6.51667 8.78906 6.31089 9.03467 6.31089 9.33084V12.7188L5.64137 11.9197C5.43188 11.7102 5.1947 11.7102 4.98522 11.9197C4.77573 12.1292 4.77578 12.3937 4.98526 12.6031L6.42999 14.0479C6.43721 14.0551 6.44444 14.0551 6.44444 14.0623C6.48778 14.1057 6.54557 14.1418 6.60336 14.1707C6.67559 14.1923 6.74063 14.2068 6.81287 14.2068C6.8851 14.2068 6.95009 14.1923 7.0151 14.1635C7.08012 14.1346 7.13791 14.0984 7.1957 14.0479L8.64042 12.6031C8.84991 12.3937 8.90329 12.1292 8.69381 11.9197Z"
                                    fill="#165FE3"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <AlertDialogFooter className="flex items-center gap-2.5 py-5 px-6 flex-row rounded-b-lg bg-gray-button-gray-lite">
            <AlertDialogCancel className="rounded-lg m-0 border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700">
              Close
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
};

export default DocumentModal;
